import React from 'react';
import { connect } from 'react-redux';
import { config } from 'hub-redux-auth';

import { onRedirectionCLick } from '../../services/utils';
import cleverTap from '../../services/cleverTap';

import API_ENDPOINTS from '../../services/apiConfig';
import RestClient from '../../services/RestClient';

import PayuBlackLogo from '@assets/images/payu-black-logo.svg';
import CsBackground from '@assets/images/cs-bg.svg';
import AuthManager from '../../services/AuthManager';

import { PlanSelect } from './PlanSelect';
import { PlanCompareTable } from './PlanCompareTable';

const PLANS = {
  1: {
    title: 'Business Plus',
    description: `Ideal for digitally aware merchants who need 24*7 business 
      assistance through a dedicated account manager and easy KYC.`,
    price: 950,
    BENEFITS: [
      'Assisted Onboarding',
      'Dedicated Key Account manager',
      'Issue Resolution',
      'Instant Product Activation',
      'Developer Connect',
      'In App support for product fitment',
      'In App analytics',
    ],
  },
  2: {
    title: 'Business Premium',
    description: `Ideal for merchants looking to scale their business through 
      our expert developer support for specific requirements.`,
    price: 1500,
    BENEFITS: ['Business Growth Support']
  }
}

const formatMoney = (number, decPlaces, decSep, thouSep) => {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;
  
  return sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

class ConsultativePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planSelected: false,
      selectedPlanType: 1,
      preSelected: 'free',
      proceedPayInProgress: false,
      newMerchant: false,
      name: ''
    }
  }

  componentWillMount() {
    document.body.style.background = 'none';
  }

  componentDidMount() {
    const user = AuthManager.getUser();
    const { cs_plan, new_merchant, name } = user ? user : {};
    const preSelectedPlanName = (cs_plan && cs_plan.name) ? cs_plan.name : 'free';
    let planSelected = false;
    if(new_merchant) {
      planSelected = preSelectedPlanName != 'free';
    }
    let selectedPlanType = 0;
    switch(preSelectedPlanName) {
      case 'business_premium':
        selectedPlanType = 2;
        break;
      case 'business_plus':
        selectedPlanType = 1;
        break;
      default:
        selectedPlanType = 0;
      break;
    }
    this.setState({
      planSelected: planSelected,
      selectedPlanType,
      preSelected: preSelectedPlanName,
      newMerchant: new_merchant, //to be changed
      name
    });
  }

  handlePlanSelection = (selectedPlanType) => {
    const user = AuthManager.getUser() || {};
    if (selectedPlanType) {
      cleverTap.event('Dashboard CTA clicked', {
        'CTA name': 'Select plan',
        'Section name': 'Plan popup',
        'CTA type': 'Action',
        MID: user.mid,
        'Product name':  PLANS[selectedPlanType].title
      })
    }

    const preSelected = selectedPlanType ?
      PLANS[selectedPlanType].title.toLowerCase().split(' ').join('_')
      : this.state.preSelected;
    this.setState({
      planSelected: !!selectedPlanType,
      preSelected,
      selectedPlanType
    })
  }
  
  selectFreePlan = () => {
    const user = AuthManager.getUser() || {};
    AuthManager.persistUserToLocalStorage({ ...user, new_merchant: false });
    cleverTap.event('Dashboard CTA clicked', {
      'CTA name': 'Select plan',
      'Section name': 'Plan popup',
      'CTA type': 'Action',
      MID: user.mid,
      'Product name': 'Starter Plan'
    })
    window.location.href = '/app/onboarding';
  }

  makePayment(payment_url, fields) {
    const form = document.createElement('FORM');
    form.name = 'cs-payment-form';
    form.method = 'POST';
    form.action = payment_url;
    for(let key in fields) {
      const inputField = document.createElement('INPUT');
      inputField.type = 'HIDDEN';
      inputField.name = key;
      inputField.value = fields[key];
      form.appendChild(inputField);
    }
    document.body.appendChild(form);
    form.submit();
  }

  async handleProceedToPay(cs_plan_name) {
    const { merchant_uuid, mid: MID,  } = AuthManager.getUser() || {};
    this.setState({
      proceedPayInProgress: true,
    });

    cleverTap.event('Dashboard CTA clicked', {
      'CTA name': 'Proceed to Pay',
      'CTA page': 'Review plan page',
      'CTA type': 'View',
      'Origin': 'Plans page',
      MID,
      'Plan name': PLANS[cs_plan_name].title.split(' ')[1]
    })

    try {
      const response = await RestClient.post(API_ENDPOINTS.CS_PAYMENT(merchant_uuid), {cs_plan_name});
      const { payment_url, ...fields } = response;
      const user = AuthManager.getUser();
      AuthManager.persistUserToLocalStorage({ ...user, new_merchant: false });
      this.makePayment(payment_url, fields);
    } catch (err) {
      this.redirectToProductIntegration();
    }
  }

  redirectToProductIntegration = () => {
    const user = AuthManager.getUser();
    AuthManager.persistUserToLocalStorage({ ...user, new_merchant: false });
    if (user && user.product.toUpperCase() === 'PAYUBIZ'){
      onRedirectionCLick();
    } else if (user && user.product.toUpperCase() === 'PAYUMONEY'){
      const productHost = config.get('moneyProductHost') || config.get('productHost') || null;
      window.location.href = `${productHost}/merchant-dashboard/#/transactions`;
    } else if (user && user.product.toUpperCase() === 'PAYUNOW'){
      const productHost = config.get('nowProductHost') || config.get('productHost') || null;
      window.location.href = `${productHost}/merchant-dashboard/#/transactions`;
    }
  };
  renderPlanReview = () => {
    const benefits = this.state.selectedPlanType<2
      ? PLANS[1].BENEFITS
      : [ ...PLANS[2].BENEFITS, ...PLANS[1].BENEFITS];
    return (
      <div className="pb-5">
        <div className="col-md-11 cs-plan-title pb-4">
          <h3 className="review">
            {
              !this.state.planSelected
              ? 'Choose the right plan for your business'
              : 'Review Your Selected Premium Plan'
            }
          </h3>
          <p className="review-sub-title">
            By purchasing your plan, you agree to PayU's{' '}
            <span><a href={`${config.get('payuWebsiteHost')}/privacy-policy`} target="_blank">privacy policy</a></span>{' '}
            and{' '}
            <span><a href={`${config.get('payuWebsiteHost')}/tnc`} target="_blank">conditions of use.</a></span>
          </p>
        </div>
        <div className="col-md-11 cs-plan-review-container">
          <CsBackground className="cs-background-review"/>
          <div className="details">
            <div className="heading">
              <h3 className="cs-plan-type">{PLANS[this.state.selectedPlanType].title}
                <span className={this.state.proceedPayInProgress ? 'cs-disable-btn' : ''}
                  onClick={() => this.state.proceedPayInProgress ? {} : this.handlePlanSelection(0)}
                >
                  CHANGE
                </span>
              </h3>
            </div>
          </div>
          <div className="row cs-ml-30">
            <div className="col-md-9 p-0">
              <div className="row">
                <div className="col-md-5">
                  <ul className="cs-plan-benefits">
                    {
                      benefits.slice(0,4).map(
                        (value, i) => <li className="tick" key={`${i}-${benefits.length}`}>{value}</li>
                      )
                    }
                  </ul>
                </div>
                <div className="col-md-5">
                  <ul className="cs-plan-benefits">
                    {
                      benefits.slice(4, benefits.length).map(
                        (value, i) => <li className="tick" key={`${i}-${benefits.length}`}>{value}</li>
                      )
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-0">
              <div className="price-breakup">
                <div className="price-tag">
                  <small>Plan Amount</small>
                  <span>₹{formatMoney(PLANS[this.state.selectedPlanType].price)}</span>
                </div>
                <div className="price-tag">
                  <small>GST (18%)</small>
                  <span>₹{formatMoney(0.18*PLANS[this.state.selectedPlanType].price)}</span>
                </div>
                <div className="price-tag">
                  <small className="black">Total Amount</small>
                  <span className="font-22">₹{formatMoney(1.18*PLANS[this.state.selectedPlanType].price)}</span>
                </div>
              </div>
              <button
                className={`pay-btn-cs${this.state.proceedPayInProgress ? ' cs-disable-btn' : ''}`}
                onClick={() => this.state.proceedPayInProgress ? {} : this.handleProceedToPay(this.state.selectedPlanType)}
              >
                PROCEED TO PAY
              </button>
            </div>
          </div>
          <div className="row">
            <div className="cs-plan-review-footer">
              <div className="footer-info">
                <ul className="cs-plan-benefits">
                  <li className="note">What’s next?</li>
                  <li className="description">
                    Once you purchase a premium plan  your dedicated account manager will 
                    get in touch with you within 24 hours to make sure you have a seamless 
                    onboarding and answer all your queries.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPlansContainer = () => {
    return (
      <div className="pb-5">
        {
          this.state.newMerchant ?
            <div className="col-md-11 cs-plan-title pb-4">
              <h3>Welcome,{' '}
                <span>{this.state.name}</span>
              </h3>
              <p className="sub-title">
              Go Premium! Select a plan to activate your account
              </p>
            </div>
          :
            <div className="col-md-11 cs-plan-title pb-4">
              <h3 className="go-premium">
                Go Premium!<br/>
                <span>Select a plan to activate your account</span>
              </h3>
            </div>
        }
        <PlanSelect
          handlePlanSelect={this.handlePlanSelection}
          selectFreePlan={this.selectFreePlan}
          newMerchant={this.state.newMerchant}
          preSelected={this.state.preSelected}
        />
        <PlanCompareTable
          handlePlanSelect={this.handlePlanSelection}
          selectFreePlan={this.selectFreePlan}
          newMerchant={this.state.newMerchant}
        />
        {
          this.state.newMerchant && (
          <div>
            <p className="separator">OR</p>
            <div className="d-flex w-100">
              <button className="explore-btn" onClick={this.redirectToProductIntegration}>EXPLORE DASHBOARD</button>
            </div>
          </div>
          )
        }
      </div>
    )
  }
  renderContent(){
    return (
      <div className="cs-bg-wave-end">
        <div className="main-content cs-bg-content">
          <div className="header-container">
            <div className="container">
              <div className="cs-plan-header">
                <a href="#"><PayuBlackLogo /></a>
                {
                  !this.state.planSelected &&
                  <a
                    href="#"
                    onClick={() => {
                      const { mid: MID } = AuthManager.getUser() || {};
                      cleverTap.event('Dashboard CTA clicked', {
                        'CTA name': this.state.newMerchant ? 'Explore Dashboard' : 'Cancel',
                        'Section name': 'Plans popup',
                        'CTA type': 'Action',
                        MID,
                      })
                      this.redirectToProductIntegration()
                    }}
                    className="underline-btn"
                    id="startIntegration"
                  >{this.state.newMerchant ? 'Explore Dashboard' : 'Cancel'}</a>
                }
              </div>
            </div>
          </div>
          <div className="container">
            {
              !this.state.planSelected
              ? this.renderPlansContainer()
              : this.renderPlanReview()
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default connect()(ConsultativePlan);
