import { takeLatest, put, select } from "redux-saga/effects";

import { stepSelectors, merchantSelectors, timelineActions, merchantActionTypes, merchantActions } from '.';
import { getSkippedSteps } from 'merchant-status-timeline';


//  Action types
export const actionTypes = {
  loadUI: "calibrate-ui/INITIATED",
  loadUISuccess: "calibrate-ui/SUCCESS",
  loadUIFailed: "calibrate-ui/FAILED",
};

/**
 * step
 *  id: identifier for component
 *  completed
 *  editable
 */
const initialState = {
  loading: false,
  err: null,
  general: {
    title: '',
    steps: [],
    inComplete: [],
  },
  kyc: {
    title: '',
    steps: [],
    inComplete: [],
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.loadUI:
    case merchantActionTypes.fetch:
      return { ...initialState, loading: true };
    case actionTypes.loadUISuccess:
      const { general, kyc } = action.payload;
      return { ...initialState, general, kyc, loading: false, };
    case actionTypes.loadUIFailed:
      return { ...initialState, err: action.payload, loading: false, };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getGeneralStage: (state) => state.onboardingApp.UI.general,
  getKYCStage: (state) => state.onboardingApp.UI.kyc,
};

//  Actions
export const actions = {
  loadUI: (merchantDetails, initialLoad) => ({
    type: actionTypes.loadUI,
    payload: { merchantDetails, initialLoad },
  }),
};

const getStepsState = (uiStage, merchantDetails) => {
  if (!uiStage?.steps || !merchantDetails)
    return;
  const skippedList = getSkippedSteps({ attachedConfigs: merchantDetails?.attached_configs });
  let stepsState = [];
  let inComplete = [];
  uiStage.steps.forEach(step => {
    const stepID = step.id;
    const {
      completed,
      incomplete,
      editable,
      filled,
      error,
      locked,
      hidden,
    } = stepSelectors.getStepProps(merchantDetails, stepID);
    if (!(skippedList.indexOf(stepID) >= 0)) {
      stepsState = stepsState.concat({ id: stepID, completed, editable, filled, error, locked, hidden: hidden });
      if (incomplete && !locked) {
        inComplete = inComplete.concat(stepID);
      }
    }
    // if (completed && step.conditionalStep) {
    //   const condStepState = stepSelectors.getStepProps(merchantDetails, step.conditionalStep);
    //   if (condStepState.incomplete && !condStepState.locked) {
    //     inComplete = inComplete.concat(step.conditionalStep);
    //   }
    //   stepsState = stepsState.concat({ id: step.conditionalStep, ...condStepState });
    // }
  });
  return { steps: stepsState, inComplete, title: uiStage?.title };
}

const DEFAULT_CONFIG = {
  title: "Activate Your Account",
  general: {
    title: 'ACCOUNT DETAILS',
    steps: [
      {
        id: "pan-details",
      },
      {
        id: "business-details",
      },
      {
        id: "bank-details",
      },
      {
        id: "website-details",
      },
    ]
  },
  kyc: {
    title: 'FULL KYC DETAILS',
    steps: [
      {
        id: "signing-authority",
      },
      {
        id: "kyc",
      },
      {
        id: "additional-documents",
      },
      {
        id: "service-agreement",
      }
    ]
  },
}

//  Workers
function* loadUIWorker(action) {
  const previousStateMerchantDetails = yield select(
    merchantSelectors.getMerchantDetails
  );
  const {
    payload: { merchantDetails: merchantDetailsPayload, initialLoad },
  } = action;
  const merchantDetails = {
    ...previousStateMerchantDetails,
    ...merchantDetailsPayload,
  };
  yield put(merchantActions.updateMerchantDetails(merchantDetails));
  const uiConfig = DEFAULT_CONFIG;

  let general = uiConfig?.general?.steps
    ? getStepsState(uiConfig.general, merchantDetails)
    : {};
  let kyc = uiConfig?.kyc?.steps
    ? getStepsState(uiConfig.kyc, merchantDetails)
    : {};

  yield put(timelineActions.updateRefreshSignal());
  yield put({
    type: actionTypes.loadUISuccess,
    payload: {
      general,
      kyc,
    },
  });
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.loadUI, loadUIWorker);
}
