import { takeLatest, call, put, select } from "redux-saga/effects";
import { config } from "hub-redux-auth";

import API_ENDPOINTS from "@services/apiConfig";
import AuthManager from "@services/AuthManager";
import axios from "axios";
import { ALLOWED_QUERY_PARAMS, AUTH_QUERY_PARAMS, PRISMIC_QUERY_PARAMS } from "@constants/queryParams";
import { getQueryParams, isASCII } from "@constants/utils";

import { uiActions, hubActions } from ".";
import { getMerchantStatuses } from "./step.duck";

//  Action types
export const actionTypes = {
  fetch: "merchantDetails/INITIATE",
  fetchFailed: "merchantDetails/FETCH_FAILED",
  fetchFulfilled: "merchantDetails/FETCH_FULFILLED",
  saveAllowedQueryParams: "SAVE_ALLOWED_QUERY_PARAMS",
  updateMerchantDetails: "merchantDetails/UPDATE",
  fetchAccountDetails: "accountDetails/INITATE",
  fetchKYCStatus: "kycStatus/INITATE",
};

const initialState = {
  fetching: false,
  fetched: false,
  response: null,
  errResponse: null,
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetch: {
      return {
        ...initialState,
        fetching: true,
      };
    }
    case actionTypes.fetchFulfilled: {
      return {
        ...initialState,
        fetched: true,
        response: action.payload,
      };
    }
    case actionTypes.fetchFailed: {
      return {
        ...initialState,
        fetched: true,
        errResponse: action.payload,
      };
    }
    case actionTypes.updateMerchantDetails: {
      return {
        ...state,
        response: { ...state.response, ...action.payload },
      };
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  merchantDetailsFetched: (state) => state.onboardingApp.merchant.fetched,
  getMerchantDetails: (state) => state.onboardingApp.merchant.response,
  getHolderName: (state) =>
    state.onboardingApp.merchant.response &&
    state.onboardingApp.merchant.response.bank_detail &&
    state.onboardingApp.merchant.response.bank_detail.holder_name,
  getNameOnPanCard: (state) =>
    state.onboardingApp.merchant.response ? state.onboardingApp.merchant.response.pancard_name : null,
  getBankVerificationStatus: (state) => {
    const merchantDetails = state.onboardingApp.merchant.response || {
      bank_detail: {},
    };
    const {
      bank_detail: { bank_verification_status },
    } = merchantDetails;
    return bank_verification_status;
  },
  getPennyStatus: (state) => {
    const merchantDetails = state.onboardingApp.merchant.response || {
      bank_detail: {},
    };
    const {
      bank_detail: { penny_deposit_status },
    } = merchantDetails;
    return penny_deposit_status;
  },
  getBankDetails: (state) => {
    const merchantDetails = state.onboardingApp.merchant.response || {
      bank_detail: {},
    };
    const { bank_detail } = merchantDetails;
    return bank_detail;
  },
  getMerchantEmail: (state) => state.onboardingApp.merchant.response && state.onboardingApp.merchant.response.email,
  getMerchantMobile: (state) =>
    state.onboardingApp.merchant.response && state.onboardingApp.merchant.response.registered_mobile,
  getMerchantSettlementStatus: (state) => (
    state.onboardingApp.merchant.response
    && state.onboardingApp.merchant.response.settlement_status
  ),
  getMerchantBusinessDetails: (state) => {
    const {
      business_category_id,
      business_category_name,
      business_sub_category_id,
      business_sub_category_name,
      gst_number,
      monthly_expected_volume,
      business_entity_id,
      business_name,
      pancard_name,
      business_pan_name_match,
      shop_number,
      area_code,
      ultimate_beneficiaries,
    } = state.onboardingApp.merchant.response || {};
    return {
      business_entity_id,
      business_category_id,
      business_category_name,
      business_sub_category_id,
      business_sub_category_name,
      gst_number,
      monthly_expected_volume,
      business_name,
      pancard_name,
      business_pan_name_match,
      shop_number,
      area_code,
      ultimate_beneficiaries,
    };
  },
  getWebsiteDetails: (state) => {
    const merchantDetails = (state.onboardingApp.merchant.response &&
      state.onboardingApp.merchant.response.website_detail) || {
      android_url: "",
      ios_url: "",
      website_url: "",
    };
    const websiteDetails = {
      android_url: merchantDetails.android_url == null ? "" : merchantDetails.android_url,
      ios_url: merchantDetails.ios_url == null ? "" : merchantDetails.ios_url,
      website_url: merchantDetails.website_url == null ? "" : merchantDetails.website_url,
      website_approval_status: merchantDetails?.website_approval_status,
    };
    return websiteDetails;
  },
  getMerchantIntegrationType: (state) => {
    return (state.onboardingApp.merchant.response && state.onboardingApp.merchant.response.integration_type) || null;
  },
  getDocumentStatus: (state) => state.onboardingApp.merchant.response && state.onboardingApp.merchant.response.document_status,
  getAccountUuid: (state) =>
    state.onboardingApp.merchant.response && state.onboardingApp.merchant.response.account_uuid,
  getSigningAuthorityDetails: (state) =>
    state.onboardingApp.merchant.response &&
    state.onboardingApp.merchant.response.account &&
    state.onboardingApp.merchant.response.account.signatory_contact_details,
  getRegistrationAddress: (state) => state.onboardingApp.merchant?.response?.registration_address,
  getOperatingAddress: (state) => state.onboardingApp.merchant?.response?.operating_address,
  getSavedKycAddress: (state) =>
    state.onboardingApp.merchant.response && state.onboardingApp.merchant.response.saved_kyc_address,
  getIsServiceAgreementEsigned: (state) => {
    const merchantDetails = state.onboardingApp.merchant.response;
    const merchantStatuses = merchantDetails?.merchant_statuses || [];
    const AgreementDetails = merchantStatuses.find(v => v.status_type === 'Agreement') || {};
    return AgreementDetails?.status_value === 'Approved' || merchantDetails.is_service_agreement_esigned;
  },
  getDob: (state) => state?.onboardingApp?.merchant?.response?.product_account_detail?.dob,
  getPepFlag: (state) => state?.onboardingApp?.merchant?.response?.product_account_detail?.pep,
  getPanNumber: (state) => state?.onboardingApp?.merchant?.response?.pancard_number,
  getPanName: (state) => state?.onboardingApp?.merchant?.response?.pancard_name,
  getMerchantGSTIn: (state) => state.onboardingApp.merchant.response?.gst_number,
  getMerchantPancardNumber: (state) => state.onboardingApp.merchant.response?.pancard_number,
  getMerchantName: (state) => state.onboardingApp.merchant.response?.name,
  getBusinessEntityType: (state) => state.onboardingApp.merchant.response?.business_entity?.name,
  getDisplayName: (state) => state.onboardingApp.merchant?.response?.display_name,
  getUBO: (state) => state.onboardingApp.merchant?.response?.ultimate_beneficiaries || [],
  isServiceAgreementGenerated: (state) => getMerchantStatuses(state.onboardingApp.merchant?.response || {})?.serviceAgreement?.generated,
  getSettlementEmbargoStatus: (state) => state.onboardingApp.merchant?.response?.new_settlement_embargo || null,
};

//  Actions
export const actions = {
  fetch: (type) => ({ type: actionTypes.fetch, payload: { type } }),
  fetchFulfilled: (response) => ({
    type: actionTypes.fetchFulfilled,
    payload: response,
  }),
  fetchFailed: (errResponse) => ({
    type: actionTypes.fetchFailed,
    payload: errResponse,
  }),
  saveAllowedQueryParams: (location) => ({
    type: actionTypes.saveAllowedQueryParams,
    payload: location,
  }),
  updateMerchantDetails: (merchantDetails) => ({
    type: actionTypes.updateMerchantDetails,
    payload: merchantDetails,
  }),
  fetchAccountDetails: (data) => ({
    type: actionTypes.fetchAccountDetails,
    payload: data
  }),
  fetchKYCStatus: () => ({ type: actionTypes.fetchKYCStatus }),
};

//  Workers
function* fetchWorker(action) {
  const { type = '' } = action.payload || { type: '' };
  try {
    const {
      data: { merchants },
    } = yield call([axios, "get"], API_ENDPOINTS.AUTH.MERCHANTS(AuthManager.getMerchantUUID())) || {
      data: { merchants: [] },
    };
    const [merchantDetails] = merchants;
    yield put(actions.fetchFulfilled(merchantDetails));
    yield put(hubActions.fetchHubToken());
    yield put(actions.fetchAccountDetails({ type }));

    AuthManager.persistUserToLocalStorage({
      merchant_uuid: merchantDetails ? merchantDetails.uuid : null,
      mid: merchantDetails ? merchantDetails.mid : null,
      name: merchantDetails ? merchantDetails.name : null,
      product: merchantDetails ? merchantDetails.product : null,
      dashboard_preference: merchantDetails ? merchantDetails.dashboard_preference : null,
      email: merchantDetails ? merchantDetails.email : null,
      cs_plan: merchantDetails ? merchantDetails.cs_plan : null,
      is_cs_eligible: merchantDetails ? merchantDetails.is_cs_eligible : null,
      merchant_type: merchantDetails ? merchantDetails.merchant_type : null,
    }, type);
  } catch (err) {
    const errResponse = yield err.response;
    console.log(errResponse, err);
    yield put(actions.fetchFailed(errResponse));
  }
}

function* saveAllowedQueryParamsWorker(action) {
  const location = action.payload;
  let queryParams = {};
  ALLOWED_QUERY_PARAMS.forEach((key) => {
    const value = getQueryParams(key, location);
    if (value && isASCII(decodeURI(value))) {
      queryParams[key] = decodeURI(value);
      key == 'reseller_id' && window.localStorage.setItem('reseller_id', value);
    }
  });
  config.set('ALLOWED_QUERY_PARAMS', queryParams);
  const campaignContext = getQueryParams(PRISMIC_QUERY_PARAMS.CAMPAIGN_CONTEXT, location);
  if (campaignContext && campaignContext?.length < 255 && isASCII(decodeURI(campaignContext))) {
    config.set(PRISMIC_QUERY_PARAMS.CAMPAIGN_CONTEXT, decodeURI(campaignContext));
  }
  const authToken = getQueryParams(AUTH_QUERY_PARAMS.authToken, location);
  const merchant_uuid = getQueryParams(AUTH_QUERY_PARAMS.merchantUuid, location);
  const appSource = getQueryParams(AUTH_QUERY_PARAMS.appSource, location);
  AuthManager.persistAppSource(appSource && isASCII(decodeURI(appSource)) ? appSource : 'NA');
  if (authToken && merchant_uuid) {
    const tokenData = {
      access_token: authToken,
      expires_in: 86399,
    };
    AuthManager.removeLocalStorageData();
    AuthManager.persistFullToken(tokenData);
    AuthManager.persistUserToLocalStorage({ merchant_uuid });
  }
}

function* fetchAccountDetailsWorker({ payload }) {
  const accountUuid = yield select(selectors.getAccountUuid);
  try {
    let response = null;
    if (accountUuid && payload.type != 'child') {
      response = yield call([axios, "get"], API_ENDPOINTS.SUBMIT_SIGNING_AUTHORITY(accountUuid)) || {};
    }
    if (response && response.data) yield put(actions.updateMerchantDetails(response.data));
    // yield put(actions.fetchKYCStatus());
    yield put(uiActions.loadUI(yield select(selectors.getMerchantDetails), true));
  } catch {
    yield put(uiActions.loadUI(yield select(selectors.getMerchantDetails), true));
  }
}

function* fetchKYCStatusWorker() {
  try {
    let response = yield call([axios, "get"], API_ENDPOINTS.KYC.STATUS(AuthManager.getMerchantUUID())) || {};
    if (response && response.data) yield put(actions.updateMerchantDetails(response.data));
    yield put(uiActions.loadUI(yield select(selectors.getMerchantDetails), true));
  } catch {
    yield put(uiActions.loadUI(yield select(selectors.getMerchantDetails), true));
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetch, fetchWorker);
  yield takeLatest(actionTypes.saveAllowedQueryParams, saveAllowedQueryParamsWorker);
  yield takeLatest(actionTypes.fetchAccountDetails, fetchAccountDetailsWorker);
  yield takeLatest(actionTypes.fetchKYCStatus, fetchKYCStatusWorker);
}
