export const REGEX = {
  GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
};

export const gstNumberRegex = value =>
  (
    value && REGEX.GSTIN.test(value)
    ? null
    : 'GST Number is invalid'
  )

export const pancardNumberRegex = value =>
  (value && /[A-Za-z]{5}[0-9]{4}[a-zA-Z]{1}$/.test(value)
    ? null
    : 'PAN Number is invalid');

export const bankAccountRegex = value =>
  (value && /^[A-Za-z0-9]+$/.test(value)
    ? null
    : 'Bank number should not contain special characters e.g.(.#$,)');

export const ifscCodeRegex = value =>
  (value && /^[A-Za-z0-9]{11}$/.test(value)
    ? null
    : 'IFSC code format is invalid');

export const aadharRegex = value =>
  (value && /^\d{12}$/.test(value) ? null : 'Aadhar number is invalid');

export const aadharShareCodeRegex = value =>
  (value && /^\d{4}$/.test(value) ? null : 'Share code is invalid');

export const cinRegex = value =>
  (value &&
    /^[A-Z]{3}-[0-9]{4}$|^[A-Z]{1}[0-9]{5}$|^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$|^F\w{3}-\d{4}$/.test(value)
    ? null
    : 'CIN number is invalid');

export const pincodeRegex = value =>
  (value && /^[1-9][0-9]{5}$/.test(value) ? null : 'Invalid Pincode');

export const addressRegex = value =>
  (value && /^.{3,}$/.test(value)
    ? null
    : 'Business Address should not be less than 3 characters');

export const websiteUrlRegex = value =>
  (!value ||
  /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(value)
    ? null
    : 'Entered URL is invalid');

export const onlyNumber = (value) => {
  if (!value) return null;
  return (/^(\d)*$/.test(value)) ? null : 'Please enter number only';
};

export const maxSales = (value) => {
  if (!value) return null;
  return /^(\d)*$/.test(value) && value <= 99999999  ? null : 'Maximum amount allowed 99999999';
}

export const nameAlphaNumRegex = (value) => {
  if (!value) return null;
  return (
    /^[A-Za-z0-9]+$/.test(value)
      ? null
      : 'This field should not contain special characters e.g.(.#$,)'
  );
}

export const brandNameRegex = value => {
  if (!value) return null;
  return (
    /^[a-zA-Z0-9&@#.(),:\-\s]*$/.test(value)
      ? null :
      'Name should contain only alphabets, numbers and special characters &@#.(),:-'
  )
}

export const emailRegex = value => (
  value && /^(?=.{6,254}$)[A-Za-z0-9_\-\.]{1,64}\@[A-Za-z0-9_\-\.]+\.[A-Za-z]{2,}$/.test(value)
    ? null
    : 'Invalid email'
);

export const mobileNumberRegex = value => (
  value && /^[6789]\d{9}$/.test(value.trim())
    ? null
    : 'Invalid mobile number'
);

export const validateGST = (gstIn, pancardNumber) => {
  if (
    !gstIn || (
      gstIn &&
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstIn.toUpperCase())
    )
  ) {
    return 'You seem to have entered an incorrect GSTIN number, kindly retry'
  }

  if (
    gstIn.length == 15
    && gstIn.slice(2, 12) != pancardNumber
  ) {
    return 'The GST number does not match with PAN details provided'
  }

  return null
}

export const allowedFileTypes = (allowedTypes = []) => {
  return v => {
    if (allowedTypes.indexOf(v?.type) < 0)  {
      return `File type must be ${allowedTypes.join(', ')}`
    }
    return null
  }
}
export const allowedFileSizeInMB = (fileSizeInMB = 0) => {
  const fileSizeInBytes = fileSizeInMB * 1024 *1024
  return v => {
    if (v?.size > fileSizeInBytes)  {
      return `Max allowed file size ${fileSizeInMB} MB`
    }
    return null
  }
}

export const isValidDecimal = value => (value && /^[0-9]*(\.[0-9]{1,2})?$/.test(value) ? null : 'Invalid format')

export const validateUBOName = value => (
  value && /^[a-zA-Z0-9!@.()\-\s]*$/.test(value)
    ? null : 'Name should contain only alphabets, numbers and special characters !@().-'
)

/**
 * Password regex
 */
export const hasMinChars = (value, minLimit) => new RegExp(`(?=.{${minLimit},})`).test(value);
export const hasUpperChar = value => /^(?=.*[A-Z])/.test(value);
export const hasLowerChar = value => /^(?=.*[a-z])/.test(value);
export const hasNumber = value => /^(?=.*[0-9])/.test(value);
export const hasSpecialChar = value => /^(?=.*[!@#\$%\^&\*])/.test(value);

export const strongPasswordRegex = (value) => {
  if (value) {
    if (!(/(?=.*[a-z])/.test(value))) {
      return 'Password must contain 1 small case letter.';
    }
    if (!(/(?=.*[A-Z])/.test(value))) {
      return 'Password must contain 1 upper case letter.';
    }
    if (!(/(?=.*[0-9])/.test(value))) {
      return 'Password must contain 1 number.';
    }
    if (!(/(?=.*[!@#\$%\^&\*])/.test(value))) {
      return 'Password must contain 1 special character.';
    }
    if((value && value.toString().length < 8)) {
      return 'Password must contain 8 characters or more.'
    }
  }
  return null;
};