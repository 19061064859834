import AuthManager from '@services/AuthManager';
import { ENUM_APP_SOURCE } from "@constants/queryParams";

export const APP_CALLBACK_ACTION = {
  LOGOUT: 'Logout',
  HARDWARE_BACK_PRESSED: 'BackPressed',
  ONBOARDING_COMPELTED: 'OnboardingCompleted',
}

export const postMessageToNativeApp = (payload) => {
  try{
    if(typeof PayUonboarding != "undefined") {
      PayUonboarding?.callback(JSON.stringify(payload));
    } else if (typeof window?.webkit?.messageHandlers?.payuOnboardingCallback?.postMessage == 'function') {
        window.webkit.messageHandlers.payuOnboardingCallback.postMessage(payload);
    }
  } catch {}
};

export const appHardwareBackEventListener = () => {
  try {
    document.addEventListener(
      "backbutton",
      () => postMessageToNativeApp({ action: APP_CALLBACK_ACTION.HARDWARE_BACK_PRESSED }),
      false
    );
  } catch {}
};

export const postMessageToWindow = (message) => {
  try { 
    if(window?.top) window.top.postMessage(message, '*');
  } catch {}
};

export const isWebView = () => {
  const appSource = AuthManager.getAppSource();
  return [ENUM_APP_SOURCE.iOS, ENUM_APP_SOURCE.ANDROID].indexOf(appSource) >= 0;
};
