import { takeLatest, call, put, select } from 'redux-saga/effects';

import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';

import {  hubSelectors } from '.';

//  Action types
export const actionTypes = {
  fetch: "partnerDetails/INITIATE",
  fetchFailed: "partnerDetails/FETCH_FAILED",
  fetchFulfilled: "partnerDetails/FETCH_FULFILLED",
  
  authorizePartner: "authorizePartner/INITIATE",
  authorizePartnerFailed: "authorizePartner/FAILED",
  authorizePartnerFulfilled: "authorizePartner/SUCCESS",

  linkPartner: "linkPartner/INITIATE",
  linkPartnerFailed: "linkPartner/FAILED",
  linkPartnerFulfilled: "linkPartner/SUCCESS",
};

const initialState = {
  fetching: false,
  fetched: false,
  response: null,
  errResponse: null,
  authorizePartner: {
    fetching: false,
    fetched: false,
    data: null,
    err: null
  },
  linkPartner: {
    fetching: false,
    fetched: false,
    data: null,
    err: null
  }
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetch: {
      return {
        ...state,
        fetching: true,
      };
    }
    case actionTypes.fetchFulfilled: {
      return {
        ...state,
        fetched: true,
        fetching: false,
        response: action.payload
      };
    }
    case actionTypes.fetchFailed: {
      return {
        ...state,
        fetched: true,
        errResponse: action.payload,
      };
    }
    case actionTypes.authorizePartner: {
      return {
        ...state,
        authorizePartner: { ...state.authorizePartner, fetching: true },
      };
    }
    case actionTypes.authorizePartnerFailed: {
      return {
        ...state,
        authorizePartner: { ...state.authorizePartner, fetched: true, errResponse: action.payload },
      };
    }
    case actionTypes.authorizePartnerFulfilled: {
      return {
        ...state,
        authorizePartner: { ...state.authorizePartner, fetched: true, data: action.payload },
      };
    }
    case actionTypes.linkPartner: {
      return {
        ...state,
        linkPartner: { ...state.linkPartner, fetching: true },
      };
    }
    case actionTypes.linkPartnerFailed: {
      return {
        ...state,
        linkPartner: { ...state.linkPartner, fetching: false, fetched: true, response: action.payload },
      };
    }
    case actionTypes.linkPartnerFulfilled: {
      return {
        ...state,
        linkPartner: { ...state.linkPartner, fetching: false, fetched: true, response: action.payload },
      };
    }
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  isPartnerFetching: (state) => state.onboardingApp.partner.fetching,
  isPartnerFetched: (state) => state.onboardingApp.partner.fetched,
  getLogo: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.logo,
  getName: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.name,
  getColorUrl: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.color_code,
  getRedirectUri: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.redirect_uri || '',
  getPermissions: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.partner_permissions || [],
  isSkipOnboardingEnabled: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.skip_onboarding_for_oauth || false,
  getConsent: (state) => state.onboardingApp.partner.response && state.onboardingApp.partner.response.oauth_consent_given || false,
  getAuthCode: (state) => state.onboardingApp.partner.authorizePartner.data && state.onboardingApp.partner.authorizePartner.data.code || false,
  getState: (state) => state.onboardingApp.partner.authorizePartner.data && state.onboardingApp.partner.authorizePartner.data.state || false
};


//  Actions
export const actions = {
  fetch: (id) => ({ type: actionTypes.fetch, payload: id }),
  fetchFulfilled: response => ({
    type: actionTypes.fetchFulfilled,
    payload: response,
  }),
  fetchFailed: errResponse => ({
    type: actionTypes.fetchFailed,
    payload: errResponse,
  }),
  authorizePartner: (data) => ({
    type: actionTypes.authorizePartner,
    payload: data
  }),
  authorizePartnerFailed: errResponse => ({
    type: actionTypes.authorizePartnerFailed,
    payload: errResponse
  }),
  authorizePartnerFulfilled: response => ({
    type: actionTypes.authorizePartnerFulfilled,
    payload: response
  }),
  linkPartner: (data) => ({
    type: actionTypes.linkPartner,
    payload: data
  }),
  linkPartnerFailed: errResponse => ({
    type: actionTypes.linkPartnerFailed,
    payload: errResponse
  }),
  linkPartnerFulfilled: response => ({
    type: actionTypes.linkPartnerFulfilled,
    payload: response
  }),
};

 const fetchPartnerConfig = (params, token) => {
    const headerParams = {
      'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': `Bearer ${token}`
    }
  return axios({
    method: "GET",
    url: API_ENDPOINTS.PARTNER.DETAILS,
    headers: headerParams,
    params,
  });
};

const linkPartner = (params) => {
  return axios({
    method: "POST",
    url: API_ENDPOINTS.PARTNER.LINK_PARTNER,
    params,
  });
};

const authorizePartner = (data) => {
  return axios({
    method: "POST",
    url: API_ENDPOINTS.PARTNER.AUTHORIZE_PARTNER,
    data
  });
};

//  Workers
function* fetchWorker(action) {
  try {
    const token = yield select(hubSelectors.getHubToken);
    const response = yield call(fetchPartnerConfig, action.payload, token);
    yield put(actions.fetchFulfilled(response.data.data));
  } catch (err) {
    const errResponse = err.response;
    console.log(errResponse, err);
    yield put(actions.fetchFailed(errResponse));
  }
}

function* linkPartnerWorker(action) {
  try {
    const response = yield call(linkPartner, action.payload);
    if(response.status == 200) {
      yield put(actions.linkPartnerFulfilled);
    }
  } catch (err) {
    console.log(yield err.response);
  }
}

function* authorizePartnerWorker(action) {
  try {
    const response = yield call(authorizePartner, action.payload);
    if(response.status == 200) {
      yield put(actions.authorizePartnerFulfilled(response.data.data.attributes));
    }
  } catch (err) {
    console.log(yield err.response);
  }
}

//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetch, fetchWorker);
  yield takeLatest(actionTypes.authorizePartner, authorizePartnerWorker);
  yield takeLatest(actionTypes.linkPartner, linkPartnerWorker);
}
