import React from 'react';

export const PlanSelect = ({
  handlePlanSelect,
  selectFreePlan,
  newMerchant,
  preSelected
}) => {
  return (
    <div className="comp-plan-list">
      <ul className="row mb-0">
        
        <li className="item col-md-4 cs-no-offer">
          <div className="hgt-wrap">
            <h3>Starter Plan</h3>
            <p>Ideal for merchants who are just digitizing payments for their business. E-payments made easy with no setup costs.</p>
            <h3 className="sub-hd-txt sub-hd-color">Free</h3>
          </div>
          <span className="plan-status no-btn">
            {
              !newMerchant ?
              'CURRENTLY ACTIVE PLAN'
              : <button className="btn btn-primary" onClick={selectFreePlan}>Select Plan</button>
            }
          </span>
        </li>
        
        <li className="item col-md-4">
          {
            preSelected === 'business_plus' && (
              <div className="selected-plan">
                CURRENTLY SELECTED
              </div>
            )
          }
          <div className="cs-mp-plan">
            <span>30% Discount</span>
          </div>
          <div className="hgt-wrap">
            <h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="star-icon">
                <circle cx="12" cy="12" r="11.5" fill="#02101A" stroke="#F3F3F3"/>
                <path fill="url(#paint0_linear)" d="M12.458 15.342l-3.98 2.548 1.15-4.586L6 10.586h4.866L12.458 6l1.593 4.586h4.954l-3.893 2.718 1.416 4.586-4.07-2.548z"/>
                  <defs>
                    <linearGradient id="paint0_linear" x1="12.502" x2="12.502" y1="6" y2="17.89" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A4DABC"/>
                      <stop offset="1" stopColor="#E8F6CC"/>
                    </linearGradient>
                  </defs>
              </svg>
              {' '}Business Plus
            </h3>
            <p>Ideal for digitally aware merchants who need business assistance through a dedicated account manager and easy KYC.</p>
            <h3 className="sub-hd-txt"><span className="prc-subtext">1601</span> <small>₹</small>1,121</h3>
            <span>One time payment including GST</span>
          </div>
          <span className="plan-status">
            <button className="btn btn-primary" onClick={() => handlePlanSelect(1)}>Select Plan</button>
          </span>
        </li>

        <li className="item col-md-4">
          {
            preSelected === 'business_premium' && (
              <div className="selected-plan r-14">
                CURRENTLY SELECTED
              </div>
            )
          }
          <div className="cs-mp-plan">
            <span>25% Discount</span>
          </div>
          <div className="hgt-wrap">
            <h3>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="star-icon">
                <circle cx="12" cy="12" r="11.5" fill="#02101A" stroke="#F3F3F3"/>
                <path fill="url(#paint0_linear)" d="M12.458 15.342l-3.98 2.548 1.15-4.586L6 10.586h4.866L12.458 6l1.593 4.586h4.954l-3.893 2.718 1.416 4.586-4.07-2.548z"/>
                <defs>
                  <linearGradient id="paint0_linear" x1="12.502" x2="12.502" y1="6" y2="17.89" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A4DABC"/>
                    <stop offset="1" stopColor="#E8F6CC"/>
                  </linearGradient>
                </defs>
              </svg>
              {' '}Business Premium
            </h3>
            <p>Ideal for merchants looking to scale their business through our expert developer support for specific requirements.</p>
            <h3 className="sub-hd-txt"><span className="prc-subtext">2360</span><small>₹</small>1,770</h3>
            <span>One time payment including GST</span>
          </div>
          <span className="plan-status">
            <button className="btn btn-primary" onClick={() => handlePlanSelect(2)}>Select Plan</button>
          </span>
        </li>
      </ul>
    </div>
  )
}