/*
 *
 * CleverTap events name
 *
 */

export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_WITH_PASSWORD_INITIATED = 'LOGIN_WITH_PASSWORD_INITIATED';
export const LOGIN_WITH_MOTP_INITIATED = 'LOGIN_WITH_MOTP_INITIATED';
export const LOGIN_WITH_EOTP_INITIATED = 'LOGIN_WITH_EOTP_INITIATED';
export const MERCHANT_FILTERED_BY_STATUS = 'MERCHANT_FILTERED_BY_STATUS';
export const OTP_VERIFIACTION_ERROR = 'OTP_VERIFIACTION_ERROR';
export const OTP_VERIFIACTION = 'OTP_VERIFIACTION';
export const LOGIN_FLOW_INITIATED = 'LOGIN_FLOW_INITIATED'
export const EMAIL_FILLED = 'EMAIL_FILLED';
export const CREATE_ACCOUNT_NEXT_CTA_ERROR = 'CREATE_ACCOUNT_NEXT_CTA_ERROR';
export const CREATE_ACCOUNT_INITIATED = 'CREATE_ACCOUNT_INITIATED';
export const CREATE_ACCOUNT_SUCCESSFULL = 'CREATE_ACCOUNT_SUCCESSFULL';
export const LOGOUT = 'LOGOUT';
export const fieldValueChange = fieldName => `${fieldName} filled`;
export const FILTER_BY = 'FILTER_BY_STATUS_OR_PRODUCT_OR_SETTLEMENT'

export const CLEVERTAP_EVENTS_MAP = {
  LOGIN_SUCCESSFULL: "Logged in successfully",
  BUSINESS_DETAILS_SUBMITTED: "Business details submitted",
  BANK_DETAILS_SUBMITTED: "Bank details submitted",
  BANK_DETAILS_UPDATED: "Bank details updated",
  DOCUMENT_UPLOADED: "Document uploaded",
  TOOL_SELECTED: "Need assessment submitted",
  PHONE_NUMBER_UPDATED: "Phone number updated",
  PASSWORD_UPDATED: "Password updated",
  ADDRESS_UPDATED: "Address updated",
  WEBSITE_UPDATED: "Website updated",
  SERVICE_AGREEMENT_ACCEPTED: "Service agreement accepted",
  DOCUMENTS_SUBMITTED_SUCCESSFUL: "Documents submitted successfully",
  CREATE_ACCOUNT_SUCCESSFULL: "Sign up platform"
}