import { takeLatest, call, select, put } from "redux-saga/effects";
import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';
import AuthManager from '@services/AuthManager';

import { merchantActions, dialogActions, websiteDetailsActions, toastActions, merchantSelectors, commentsActions, hubActions } from '.';
import { HubAuthManager } from "hub-redux-auth";

//  Action types
export const actionTypes = {
  saveEditWebsiteDetails: 'saveEditWebsiteDetails/SAVE',
  editWebsite: 'editWebsite/INITIATE',
  saveGSTDetails: 'saveEditGSTDetails/SAVE',
  editGST: 'editGST/INITIATE',
  mobileUpdateFormOpen: 'mobileUpdateForm/OPEN',
  createUserMobileUpdate: 'createUserMobileUpdate/INITIATE',
  saveNewUserData: 'mobileUpdate/saveNewUserData',
  saveNewToken: 'mobileUpdate/saveNewToken',
  emailVerification: 'mobileUpdate/emailVerification',
  emailVerificationVerified: 'edit/emailVerificationVerified',
};

const initialState = {
  website: {},
  gst: {},
  newUserData: {},
  newTokenData: {},
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.saveEditWebsiteDetails:
      return { ...state, website: { ...action.payload } };
    case actionTypes.saveGSTDetails:
      return { ...state, gst: { ...action.payload } };
    case actionTypes.saveNewUserData:
      return { ...state, newUserData: { ...action.payload } };
    case actionTypes.saveNewToken:
      return { ...state, newTokenData: { ...action.payload } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getSavedEditWebsiteDetails: (state) => state.onboardingApp?.edit?.website,
  getSavedEditGSTDetails: (state) => state.onboardingApp?.edit?.gst,
  getNewUserData: (state) => state.onboardingApp?.edit?.newUserData,
  getNewToken: state => state.onboardingApp?.edit?.newTokenData?.access_token,
  getNewTokenData: state => state.onboardingApp?.edit?.newTokenData,
};


//  Actions
export const actions = {
  saveEditWebsiteDetails: (payload) => ({ type: actionTypes.saveEditWebsiteDetails, payload }),
  saveGSTDetails: (payload) => ({ type: actionTypes.saveGSTDetails, payload }),
  saveNewUserData: payload => ({ type: actionTypes.saveNewUserData, payload }),
  saveNewToken: payload => ({ type: actionTypes.saveNewToken, payload }),
};

// Workers
function* editWebsiteWorker(action) {
  const websiteDetails = yield select(selectors.getSavedEditWebsiteDetails);
  yield put(dialogActions.open('website-ws-loader'));
  try {
    const {
      data: { website_detail },
    } = yield call(
      [axios, 'post'],
      API_ENDPOINTS.ONBOARDING.LIVELINESS_CHECK(AuthManager.getMerchantUUID()),
      { website_detail: { ...websiteDetails, addendum_status: 'accepted' } },
      { token: action?.payload?.access_token },
    );
    yield put(merchantActions.updateMerchantDetails({ website_detail }));
    yield put(websiteDetailsActions.saveWebsiteUUID(website_detail?.uuid));
    if (websiteDetails?.comments || websiteDetails?.file) [
      yield put(commentsActions.submitWebisteComment({ comment: websiteDetails?.comments, file: websiteDetails?.file, urlNotUpdated: false }))
    ]
  } catch (err) {
    yield put(dialogActions.close());
    console.log(err)
  }
}

function* editGSTWorker(action) {
  const gstDetails = yield select(selectors.getSavedEditGSTDetails);
  try {
    const { data } = yield call(
      [axios, "put"],
      API_ENDPOINTS.UPDATE_GSTN(AuthManager.getMerchantUUID()),
      { ...gstDetails, gst_addendum_status: 'accepted' },
      { token: action?.payload?.access_token },
    );
    yield put(merchantActions.updateMerchantDetails(data.merchant));
    yield put(toastActions.show({
      variant: 'success',
      title: 'Success',
      content: 'GST number updated'
    }))
  } catch (err) {
    const errResponse = yield err.response;
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: errResponse?.data?.errors?.gst_number?.[0] || 'Something went wrong please try after some time'
    }))
  }
}

function* mobileUpdateFormOpenWorker(action) {
  yield put(dialogActions.open('mobile-update-form', action.payload));
}

function* createUserMobileUpdateWorker(action) {
  const newTokenData = yield select(selectors.getNewTokenData)
  try {
    yield call(
      [axios, 'post'],
      API_ENDPOINTS.ONBOARDING.CREATE_USER(action?.payload?.user_uuid),
      { email: yield select(merchantSelectors.getMerchantEmail) },
      { token: yield select(selectors.getNewToken) }
    );
    const newUserData = yield select(selectors.getNewUserData)
    yield put(merchantActions.updateMerchantDetails({ registered_mobile: newUserData?.data?.data?.[0]?.attributes?.mobile }));
    window.localStorage.setItem('tokenData', JSON.stringify(newTokenData));
    const user = AuthManager.getUser() || {};
    HubAuthManager.persistUserToLocalStorage({ ...user, uuid: newTokenData?.user_uuid });
    yield put(toastActions.show({
      variant: 'success',
      title: 'Success',
      content: 'Mobile number updated'
    }))
  } catch {
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: 'Something went wrong please try after some time'
    }))
  }
}

function* emailVerificationWorker(action) {
  yield put(actions.saveNewToken(action.payload));
  yield put(dialogActions.open('email-verification-mobile-update', action.payload));
}

function* emailVerificationVerifiedWorker(action) {
  yield put(toastActions.show({
    variant: 'success',
    title: 'Success',
    content: 'Email verified',
  }));
  yield put(hubActions.findOrCreateEmail());
}

// Sagas
export function* saga() {
  yield takeLatest(actionTypes.editWebsite, editWebsiteWorker);
  yield takeLatest(actionTypes.editGST, editGSTWorker);
  yield takeLatest(actionTypes.mobileUpdateFormOpen, mobileUpdateFormOpenWorker);
  yield takeLatest(actionTypes.createUserMobileUpdate, createUserMobileUpdateWorker);
  yield takeLatest(actionTypes.emailVerification, emailVerificationWorker);
  yield takeLatest(actionTypes.emailVerificationVerified, emailVerificationVerifiedWorker);
}