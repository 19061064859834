import React, { useState } from 'react';
import Zoho from '@assets/images/zoho.png';
import Delhivery from '@assets/images/delhivery.png';
import Close from '@assets/images/cs-cross.svg';
import Tick from '@assets/images/cs-tick.svg';


export const PlanCompareTable = ({
  handlePlanSelect,
  selectFreePlan,
  newMerchant
}) => {
  const [showTable, setShowTable] = useState(false);

  return (
    <div className="comp-plan-table">
      <div
        className="plan-more-list"
        onClick={() => setShowTable(!showTable)}
      >
        <span className={`cs-plan-tab${showTable ? '' : ' close-arrow-table'}`}>
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.70801L5.2371 4.72159C4.84077 5.15387 4.15923 5.15387 3.7629 4.72159L1 1.70801" stroke="#18AF8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="bevel"/>
          </svg>
          {' '}COMPARE PLAN FEATURES
        </span>
      </div>
      {
        showTable && (
        <div className="plan-table-main">

        <table cellSpacing="0" cellPadding="0" border="0" className="plan-table-con desktop-view-table">
          <tbody>
            <tr>
              <th align="left" valign="top"></th>
              <th align="center" valign="top">STARTER</th>
              <th align="center" valign="top">BUSINESS PLUS</th>
              <th align="center" valign="top">BUSINESS PREMIUM</th>
            </tr>

            <tr>
              <td align="left" valign="top" className="td-space">Assisted Onboarding</td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>

            <tr>
              <td align="left" valign="top" className="td-space">Dedicated Key Account manager</td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>

            <tr>
              <td align="left" valign="top" className="td-space">Issue Resolution</td>
              <td align="center" valign="top" className="td-space">Standard</td>
              <td align="center" valign="top" className="td-space green-txt">Priority</td>
              <td align="center" valign="top" className="td-space green-txt">Priority</td>
            </tr>

            <tr>
              <td align="left" valign="top" className="td-space">Instant Product Activation</td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>

            <tr>
              <td align="left" valign="top" className="td-space">Transaction Charges</td>
              <td align="center" valign="top" className="td-space">≈ 2% of transaction value</td>
              <td align="center" valign="top" className="td-space">Free for first ₹ 50K worth of transactions
                <div className="nb-one">Access to Exclusive Pricing</div>
              </td>
              <td align="center" valign="top" className="td-space">Free for first ₹ 50K worth of transactions
                <div className="nb-one">Access to Exclusive Pricing</div>
              </td>
            </tr>
            
            <tr>
              <td align="left" valign="top" className="td-space">
                <span className="tooltip-main">Developer Connect
                  <span className="info-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                      <path fill="#4D4D4D" d="M8 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 12.813c-3.21 0-5.813-2.604-5.813-5.813 0-3.21 2.604-5.813 5.813-5.813 3.21 0 5.813 2.604 5.813 5.813 0 3.21-2.604 5.813-5.813 5.813z"/>
                      <path fill="#4D4D4D" d="M8 2.188C4.79 2.188 2.187 4.79 2.187 8c0 3.21 2.604 5.813 5.813 5.813 3.21 0 5.813-2.604 5.813-5.813 0-3.21-2.604-5.813-5.813-5.813zm0 9.874c-.345 0-.625-.28-.625-.624 0-.346.28-.626.625-.626s.625.28.625.626c0 .345-.28.624-.625.624zm.983-3.43c-.142.055-.263.151-.35.276-.086.125-.132.273-.133.425v.354c0 .07-.056.126-.125.126h-.75c-.069 0-.125-.057-.125-.126v-.335c0-.361.105-.718.31-1.014.202-.291.484-.513.815-.64.531-.204.875-.65.875-1.136 0-.689-.673-1.25-1.5-1.25s-1.5.561-1.5 1.25v.12c0 .068-.056.124-.125.124h-.75c-.069 0-.125-.056-.125-.125v-.119c0-.614.269-1.187.756-1.614.469-.41 1.088-.636 1.744-.636.656 0 1.275.227 1.744.636.487.427.756 1 .756 1.614 0 .904-.595 1.716-1.517 2.07z"/>
                      <path fill="#fff" d="M7.375 11.438c0 .165.066.324.183.441.117.118.276.184.442.184.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441 0-.166-.066-.325-.183-.442-.117-.118-.276-.184-.442-.184-.166 0-.325.066-.442.184-.117.117-.183.276-.183.441zm2.369-6.49C9.275 4.54 8.656 4.312 8 4.312c-.656 0-1.275.225-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75c.069 0 .125-.056.125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.875 1.136-.331.127-.613.349-.814.64-.206.296-.311.653-.311 1.014v.335c0 .07.056.126.125.126h.75c.069 0 .125-.057.125-.126v-.354c0-.152.047-.3.133-.425.087-.125.208-.22.35-.275.922-.355 1.517-1.167 1.517-2.07 0-.615-.269-1.188-.756-1.615z"/>
                    </svg>
                
                    <div className="tooltip-wrap">
                      <div className="tooltip-text">
                          <p>We provide a marketplace of developers so you can hand-pick resources based on specific requirements.</p>
                      </div>
                    </div>
                  </span>
                </span>
              </td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
            
            <tr>
              <td align="left" valign="top" className="td-space">
                <span className="tooltip-main">
                  Business Growth Support
                  <span className="info-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                      <path fill="#4D4D4D" d="M8 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 12.813c-3.21 0-5.813-2.604-5.813-5.813 0-3.21 2.604-5.813 5.813-5.813 3.21 0 5.813 2.604 5.813 5.813 0 3.21-2.604 5.813-5.813 5.813z"/>
                      <path fill="#4D4D4D" d="M8 2.188C4.79 2.188 2.187 4.79 2.187 8c0 3.21 2.604 5.813 5.813 5.813 3.21 0 5.813-2.604 5.813-5.813 0-3.21-2.604-5.813-5.813-5.813zm0 9.874c-.345 0-.625-.28-.625-.624 0-.346.28-.626.625-.626s.625.28.625.626c0 .345-.28.624-.625.624zm.983-3.43c-.142.055-.263.151-.35.276-.086.125-.132.273-.133.425v.354c0 .07-.056.126-.125.126h-.75c-.069 0-.125-.057-.125-.126v-.335c0-.361.105-.718.31-1.014.202-.291.484-.513.815-.64.531-.204.875-.65.875-1.136 0-.689-.673-1.25-1.5-1.25s-1.5.561-1.5 1.25v.12c0 .068-.056.124-.125.124h-.75c-.069 0-.125-.056-.125-.125v-.119c0-.614.269-1.187.756-1.614.469-.41 1.088-.636 1.744-.636.656 0 1.275.227 1.744.636.487.427.756 1 .756 1.614 0 .904-.595 1.716-1.517 2.07z"/>
                      <path fill="#fff" d="M7.375 11.438c0 .165.066.324.183.441.117.118.276.184.442.184.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441 0-.166-.066-.325-.183-.442-.117-.118-.276-.184-.442-.184-.166 0-.325.066-.442.184-.117.117-.183.276-.183.441zm2.369-6.49C9.275 4.54 8.656 4.312 8 4.312c-.656 0-1.275.225-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75c.069 0 .125-.056.125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.875 1.136-.331.127-.613.349-.814.64-.206.296-.311.653-.311 1.014v.335c0 .07.056.126.125.126h.75c.069 0 .125-.057.125-.126v-.354c0-.152.047-.3.133-.425.087-.125.208-.22.35-.275.922-.355 1.517-1.167 1.517-2.07 0-.615-.269-1.188-.756-1.615z"/>
                    </svg>     
                    <div className="tooltip-wrap">
                      <div className="tooltip-text">
                        <p>Get Zoho credits in the range of 3000 - 5000 on successful sign up.</p>
                      </div>
                    </div>
                  </span>
                </span>
              </td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <div className="tbl-img-wrap">
                  <img src={Zoho} />                    
                </div>
                <div className="nb-one nb-two">Credits worth ₹3000</div>
              </td>
              <td align="center" valign="top" className="td-space">
                <div className="tbl-img-wrap">
                  <img src={Zoho} />                    
                </div>
                <div className="nb-one nb-two">Credits worth ₹5000</div>
              </td>
            </tr>
                    
            <tr>
              <td align="left" valign="top" className="td-space">
                <span className="tooltip-main">Logistic Support
                  <span className="info-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                      <path fill="#4D4D4D" d="M8 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 12.813c-3.21 0-5.813-2.604-5.813-5.813 0-3.21 2.604-5.813 5.813-5.813 3.21 0 5.813 2.604 5.813 5.813 0 3.21-2.604 5.813-5.813 5.813z"/>
                      <path fill="#4D4D4D" d="M8 2.188C4.79 2.188 2.187 4.79 2.187 8c0 3.21 2.604 5.813 5.813 5.813 3.21 0 5.813-2.604 5.813-5.813 0-3.21-2.604-5.813-5.813-5.813zm0 9.874c-.345 0-.625-.28-.625-.624 0-.346.28-.626.625-.626s.625.28.625.626c0 .345-.28.624-.625.624zm.983-3.43c-.142.055-.263.151-.35.276-.086.125-.132.273-.133.425v.354c0 .07-.056.126-.125.126h-.75c-.069 0-.125-.057-.125-.126v-.335c0-.361.105-.718.31-1.014.202-.291.484-.513.815-.64.531-.204.875-.65.875-1.136 0-.689-.673-1.25-1.5-1.25s-1.5.561-1.5 1.25v.12c0 .068-.056.124-.125.124h-.75c-.069 0-.125-.056-.125-.125v-.119c0-.614.269-1.187.756-1.614.469-.41 1.088-.636 1.744-.636.656 0 1.275.227 1.744.636.487.427.756 1 .756 1.614 0 .904-.595 1.716-1.517 2.07z"/>
                      <path fill="#fff" d="M7.375 11.438c0 .165.066.324.183.441.117.118.276.184.442.184.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441 0-.166-.066-.325-.183-.442-.117-.118-.276-.184-.442-.184-.166 0-.325.066-.442.184-.117.117-.183.276-.183.441zm2.369-6.49C9.275 4.54 8.656 4.312 8 4.312c-.656 0-1.275.225-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75c.069 0 .125-.056.125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.875 1.136-.331.127-.613.349-.814.64-.206.296-.311.653-.311 1.014v.335c0 .07.056.126.125.126h.75c.069 0 .125-.057.125-.126v-.354c0-.152.047-.3.133-.425.087-.125.208-.22.35-.275.922-.355 1.517-1.167 1.517-2.07 0-.615-.269-1.188-.756-1.615z"/>
                    </svg>            
                    <div className="tooltip-wrap">
                      <div className="tooltip-text">
                        <p>Get Discounted rates from Delhivery on successful sign up.</p>
                      </div>  
                    </div>
                  </span>
                </span>
              </td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <div className="tbl-img-wrap">
                  <img src={Delhivery}/>
                </div>
                <div className="nb-one nb-two">Discount upto 12%</div>
              </td>
            </tr>

            <tr>
              <td align="left" valign="top" className="td-space">
                In App support for product fitment
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
                            
            <tr>
              <td align="left" valign="top" className="td-space">
                In App analytics
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
            
            <tr>
              <td align="left" valign="top" className="td-space">
                Key Details
              </td>
              <td align="left" valign="top" className="td-space">
                <p className="cs-td-pln">Setting: Remote</p>
                {
                  newMerchant ?
                  <button className="cs-plan-status green-color" onClick={selectFreePlan}>Sign up, It’s FREE!</button>
                  :
                  <button className="cs-plan-status no-cur">CURRENTLY ACTIVE PLAN</button>
                }
              </td>
              <td align="left" valign="top" className="td-space">
                <p className="cs-td-pln">Setting: Remote</p>
                <button className="cs-plan-status green-color" onClick={() => handlePlanSelect(1)} >BUY NOW at ₹ 1,121</button>
              </td>
              <td align="left" valign="top" className="td-space">
                <p className="cs-td-pln">Setting: Remote</p>
                <button className="cs-plan-status green-color" onClick={() => handlePlanSelect(2)} >BUY NOW at ₹ 1,770</button>
              </td>
            </tr>

          </tbody>
        </table>
      
        <table cellSpacing="0" cellPadding="0" border="0" className="plan-table-con mob-view-table">
          <tbody>

            <tr>
              <th align="left" valign="middle">STARTER</th>
              <th align="left" valign="middle">BUSINESS PLUS</th>
              <th align="left" valign="middle">BUSINESS PREMIUM</th>
            </tr>

            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">Assisted Onboarding</td>
            </tr>
    
            <tr>
              <td align="center" valign="top" className="td-space rght-bod">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>

            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">Dedicated Key Account manager</td>
            </tr>
    
            <tr>
              <td align="center" valign="top" className="td-space rght-bod">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
    
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">Issue Resolution </td>
            </tr>
    
            <tr>
              <td align="center" valign="top" className="td-space rght-bod">Standard</td>
              <td align="center" valign="top" className="td-space rght-bod green-txt">Priority</td>
              <td align="center" valign="top" className="td-space green-txt">Priority</td>
            </tr>
        
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">Transaction Charges</td>
            </tr>
            
            <tr>
              <td align="center" valign="top" className="td-space rght-bod">≈ 2% of transaction value</td>
              <td align="center" valign="top" className="td-space rght-bod">Free for first ₹ 50K worth of transactions
                <div className="nb-one">Access to Exclusive Pricing</div>
              </td>
              <td align="center" valign="top" className="td-space">Free for first ₹ 50K worth of transactions
                <div className="nb-one">Access to Exclusive Pricing</div>
              </td>
            </tr>
                
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">
                <span className="tooltip-main">Developer Connect
                  <span className="info-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                      <path fill="#4D4D4D" d="M8 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 12.813c-3.21 0-5.813-2.604-5.813-5.813 0-3.21 2.604-5.813 5.813-5.813 3.21 0 5.813 2.604 5.813 5.813 0 3.21-2.604 5.813-5.813 5.813z"/>
                      <path fill="#4D4D4D" d="M8 2.188C4.79 2.188 2.187 4.79 2.187 8c0 3.21 2.604 5.813 5.813 5.813 3.21 0 5.813-2.604 5.813-5.813 0-3.21-2.604-5.813-5.813-5.813zm0 9.874c-.345 0-.625-.28-.625-.624 0-.346.28-.626.625-.626s.625.28.625.626c0 .345-.28.624-.625.624zm.983-3.43c-.142.055-.263.151-.35.276-.086.125-.132.273-.133.425v.354c0 .07-.056.126-.125.126h-.75c-.069 0-.125-.057-.125-.126v-.335c0-.361.105-.718.31-1.014.202-.291.484-.513.815-.64.531-.204.875-.65.875-1.136 0-.689-.673-1.25-1.5-1.25s-1.5.561-1.5 1.25v.12c0 .068-.056.124-.125.124h-.75c-.069 0-.125-.056-.125-.125v-.119c0-.614.269-1.187.756-1.614.469-.41 1.088-.636 1.744-.636.656 0 1.275.227 1.744.636.487.427.756 1 .756 1.614 0 .904-.595 1.716-1.517 2.07z"/>
                      <path fill="#fff" d="M7.375 11.438c0 .165.066.324.183.441.117.118.276.184.442.184.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441 0-.166-.066-.325-.183-.442-.117-.118-.276-.184-.442-.184-.166 0-.325.066-.442.184-.117.117-.183.276-.183.441zm2.369-6.49C9.275 4.54 8.656 4.312 8 4.312c-.656 0-1.275.225-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75c.069 0 .125-.056.125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.875 1.136-.331.127-.613.349-.814.64-.206.296-.311.653-.311 1.014v.335c0 .07.056.126.125.126h.75c.069 0 .125-.057.125-.126v-.354c0-.152.047-.3.133-.425.087-.125.208-.22.35-.275.922-.355 1.517-1.167 1.517-2.07 0-.615-.269-1.188-.756-1.615z"/>
                    </svg>
                    <div className="tooltip-wrap">
                      <div className="tooltip-text">
                        <p>We provide a marketplace of developers so you can hand-pick resources based on specific requirements.</p>
                      </div>
                    </div>
                  </span>
                </span>
              </td>
            </tr>
            
            <tr>
              <td align="center" valign="top" className="td-space rght-bod">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
                    
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">
                <span className="tooltip-main">Business Growth Support
                  <span className="info-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                      <path fill="#4D4D4D" d="M8 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 12.813c-3.21 0-5.813-2.604-5.813-5.813 0-3.21 2.604-5.813 5.813-5.813 3.21 0 5.813 2.604 5.813 5.813 0 3.21-2.604 5.813-5.813 5.813z"/>
                      <path fill="#4D4D4D" d="M8 2.188C4.79 2.188 2.187 4.79 2.187 8c0 3.21 2.604 5.813 5.813 5.813 3.21 0 5.813-2.604 5.813-5.813 0-3.21-2.604-5.813-5.813-5.813zm0 9.874c-.345 0-.625-.28-.625-.624 0-.346.28-.626.625-.626s.625.28.625.626c0 .345-.28.624-.625.624zm.983-3.43c-.142.055-.263.151-.35.276-.086.125-.132.273-.133.425v.354c0 .07-.056.126-.125.126h-.75c-.069 0-.125-.057-.125-.126v-.335c0-.361.105-.718.31-1.014.202-.291.484-.513.815-.64.531-.204.875-.65.875-1.136 0-.689-.673-1.25-1.5-1.25s-1.5.561-1.5 1.25v.12c0 .068-.056.124-.125.124h-.75c-.069 0-.125-.056-.125-.125v-.119c0-.614.269-1.187.756-1.614.469-.41 1.088-.636 1.744-.636.656 0 1.275.227 1.744.636.487.427.756 1 .756 1.614 0 .904-.595 1.716-1.517 2.07z"/>
                      <path fill="#fff" d="M7.375 11.438c0 .165.066.324.183.441.117.118.276.184.442.184.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441 0-.166-.066-.325-.183-.442-.117-.118-.276-.184-.442-.184-.166 0-.325.066-.442.184-.117.117-.183.276-.183.441zm2.369-6.49C9.275 4.54 8.656 4.312 8 4.312c-.656 0-1.275.225-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75c.069 0 .125-.056.125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.875 1.136-.331.127-.613.349-.814.64-.206.296-.311.653-.311 1.014v.335c0 .07.056.126.125.126h.75c.069 0 .125-.057.125-.126v-.354c0-.152.047-.3.133-.425.087-.125.208-.22.35-.275.922-.355 1.517-1.167 1.517-2.07 0-.615-.269-1.188-.756-1.615z"/>
                    </svg>
                    <div className="tooltip-wrap">
                      <div className="tooltip-text">
                        <p>Get Zoho credits in the range of 3000 - 5000 on successful sign up.</p>
                      </div>  
                    </div>
                  </span>
                </span>
              </td>
            </tr>
            
            <tr>            
              <td align="center" valign="top" className="td-space rght-bod">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <div className="tbl-img-wrap">
                  <img src={Zoho}/>
                </div>
                <div className="nb-one nb-two">Credits worth ₹3000</div>
              </td>
              <td align="center" valign="top" className="td-space">
                <div className="tbl-img-wrap">
                  <img src={Zoho}/>
                </div>
                <div className="nb-one nb-two">Credits worth ₹5000</div>
              </td>
            </tr>
            
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">
                <span className="tooltip-main">Logistic Support
                  <span className="info-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                      <path fill="#4D4D4D" d="M8 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 12.813c-3.21 0-5.813-2.604-5.813-5.813 0-3.21 2.604-5.813 5.813-5.813 3.21 0 5.813 2.604 5.813 5.813 0 3.21-2.604 5.813-5.813 5.813z"/>
                      <path fill="#4D4D4D" d="M8 2.188C4.79 2.188 2.187 4.79 2.187 8c0 3.21 2.604 5.813 5.813 5.813 3.21 0 5.813-2.604 5.813-5.813 0-3.21-2.604-5.813-5.813-5.813zm0 9.874c-.345 0-.625-.28-.625-.624 0-.346.28-.626.625-.626s.625.28.625.626c0 .345-.28.624-.625.624zm.983-3.43c-.142.055-.263.151-.35.276-.086.125-.132.273-.133.425v.354c0 .07-.056.126-.125.126h-.75c-.069 0-.125-.057-.125-.126v-.335c0-.361.105-.718.31-1.014.202-.291.484-.513.815-.64.531-.204.875-.65.875-1.136 0-.689-.673-1.25-1.5-1.25s-1.5.561-1.5 1.25v.12c0 .068-.056.124-.125.124h-.75c-.069 0-.125-.056-.125-.125v-.119c0-.614.269-1.187.756-1.614.469-.41 1.088-.636 1.744-.636.656 0 1.275.227 1.744.636.487.427.756 1 .756 1.614 0 .904-.595 1.716-1.517 2.07z"/>
                      <path fill="#fff" d="M7.375 11.438c0 .165.066.324.183.441.117.118.276.184.442.184.166 0 .325-.066.442-.184.117-.117.183-.276.183-.441 0-.166-.066-.325-.183-.442-.117-.118-.276-.184-.442-.184-.166 0-.325.066-.442.184-.117.117-.183.276-.183.441zm2.369-6.49C9.275 4.54 8.656 4.312 8 4.312c-.656 0-1.275.225-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75c.069 0 .125-.056.125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.875 1.136-.331.127-.613.349-.814.64-.206.296-.311.653-.311 1.014v.335c0 .07.056.126.125.126h.75c.069 0 .125-.057.125-.126v-.354c0-.152.047-.3.133-.425.087-.125.208-.22.35-.275.922-.355 1.517-1.167 1.517-2.07 0-.615-.269-1.188-.756-1.615z"/>
                    </svg>
                    <div className="tooltip-wrap">
                      <div className="tooltip-text">
                        <p>Get Discounted rates from Delhivery on successful sign up.</p>
                      </div> 
                    </div>
                  </span>
                </span>
              </td>
            </tr>
            
            <tr>
              <td align="center" valign="top" className="td-space rght-bod">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Close/>
              </td>
              <td align="center" valign="top" className="td-space">
                <div className="tbl-img-wrap">
                  <img src={Delhivery}/>
                </div>
                <div className="nb-one nb-two">Discount upto 12%</div>
              </td>
            </tr>
                            
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">
                In App support for product fitment
              </td>
            </tr>
            
            <tr>                   
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
            
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">
                In App analytics
              </td>
            </tr>
            
            <tr>                     
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
            
            <tr>
              <td colSpan="4" align="left" valign="top" className="td-space td-heading-mob">
                Key Details
              </td>
            </tr>
            
            <tr>                              
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>   
              </td>
              <td align="center" valign="top" className="td-space rght-bod">
                <Tick/>
              </td>
              <td align="center" valign="top" className="td-space">
                <Tick/>
              </td>
            </tr>
    
          </tbody>
        </table>
      
      </div>
        )
      }
    </div>
  )
}