import React, { Suspense } from 'react';
import { connect } from 'react-redux';
// async/await will function only after babel-polyfill has been loaded
import 'babel-polyfill';
import { config } from 'hub-redux-auth';

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import L from 'react-loadable';
import { RedirectionService } from 'hub-redux-auth';

import NotFound from '@components/BaseComponents/NotFound';
import AuthManager from './services/AuthManager';
import PrivateRoute from './components/BaseComponents/PrivateRoute';
import AppLoader from './components/BaseComponents/AppLoader';
import BankDetailsFlow from './containers/BankDetailsFlow';
import PennyDetails from './containers/PennyDetails';
import WelcomePage from './containers/WelcomePage';
import ConsultativePlan from './containers/ConsultativePlan';
import NeedAssessment from './containers/NeedAssessment';
import { hasNowMerchantCameToMoney } from './services/utils';
import ManageStaff from './containers/ManageStaff';
const OnboardingRoutes = React.lazy(() => import(/* webpackChunkName: "OnboardingRoute" */'../onboarding/router/Routes'));
import { ServiceLogin, ServiceLogout } from '@containers/Account'
import { merchantActions } from '@store/ducks';

/**
 * lazy loading using webpack code splitting
 * AppLoader will be used as loader if module takes
 * longer than the mentioned delay
 * @param  {[type]} opts options for react loadable
 * @return {function}  Component
 */
const Loadable = (opts) => L({
    loading: AppLoader,
    delay: 300, // 0.3 seconds
    ...opts
  }),

/*
 * webpack Dynamic loading for auth
 * So there will be 3 chunks for the webapp
 * First for the authentication which will kick
 * in only if user is unauthenticated
 * Second for the dashboard app and third for onboarding
 */
 AsyncAuthApp = Loadable({
  loader: () => import('./containers/AuthApp')
}),


//  AsyncOnboardingApp = Loadable({
//   loader: () => import('./containers/OnboardingApp')
// }),

  AsyncOnboardingApp = Loadable({
  loader: () => import('./containers/OnboardingApp')
}),

 OauthAuthorize = Loadable({
  loader: () => import('./containers/OauthAuthorize')
}),

 AsyncTermsConditions = Loadable({
  loader: () => import('./containers/TermsConditions')
}),

 SessionCheckApp = Loadable({
  loader: () => import('./containers/SessionCheck')
}),

 isTncRouteAllowed = (state) => {
  if (AuthManager.isLoggedIn()) {
    const user = AuthManager.getUser();
    return user && hasNowMerchantCameToMoney(user.business_origin, user.product, state.product);
  }
  return false;
},

 isOnboardingRouteAllowed = (state) => {
  if (AuthManager.isLoggedIn()) {
    const { merchant_uuid: merchantUuid } = AuthManager.getUser();
    if (!merchantUuid) {
      window.location = '/app/account/team-accounts';
    } else {
      return !isTncRouteAllowed(state);
    }
  }
  return false;
},

 isSettingRouteAllowed = (state) => {
  const user = AuthManager.getUser();
  /* team-accounts allowed only from PUM merchants */
  if (AuthManager.isLoggedIn() && user && user.product.toUpperCase() === 'PAYUMONEY') {
    return !isTncRouteAllowed(state);
  }
  return false;
},

isPlanRouteAllowed = () => {
  const user = AuthManager.getUser();
  /* consultative-selling allowed only from PUB one-dashboard merchants */
  const { cs_plan, is_cs_eligible=false } = user ? user : {};
  /* cs_plan status *****
   * plan_selected:     1
   * payment_initiated: 2
   * payment_failed:    3
   * payment_completed: 4
   * kam_assigned:      5
  ***********************/
  const isPlanNotActive = !(cs_plan && ['payment_completed', 'kam_assigned'].includes(cs_plan.status));
  return (
    AuthManager.isLoggedIn() &&
    is_cs_eligible &&
    isPlanNotActive
  );
},

isAssessmentRouteAllowed = () => {
  const user = AuthManager.getUser();
  const { new_merchant } = user ? user : {};
  return (
    AuthManager.isLoggedIn() &&
    new_merchant
  );
},

 redirectTo = (state) => {
  if (AuthManager.isLoggedIn() && isTncRouteAllowed(state)) {
    return '/terms-conditions';
  }
  return `/account${config.get('utmParameters', '')}`;
},

 setupProductRedirection = (props) => {
  const { location } = props;
  RedirectionService.setURL(location.search, config.get('productRedirectUrl'));
};

const AppRoutes = () => (
  <Router basename="/app">
    <Suspense fallback={<p>...</p>}>
    <Switch>
      <Redirect exact from="/" to="/account" />
      <Route
        path="/account"
        component={SessionCheckApp}
      />
      <PrivateRoute
        path="/oauth/authorize"
        isAllowed={() => AuthManager.isLoggedIn()}
        component={OauthAuthorize}
        setRedirection={setupProductRedirection}
        redirectTo={`/account?redirectTo=${window.location.origin}/app/oauth/authorize${encodeURIComponent(decodeURIComponent(window.location.search))}`}
      />
      <PrivateRoute
        path="/bank-details"
        isAllowed={() => AuthManager.isLoggedIn()}
        redirectTo="/account"
        component={BankDetailsFlow}
      />
      <PrivateRoute
        path="/penny-details"
        isAllowed={() => AuthManager.isLoggedIn()}
        redirectTo="/account"
        component={PennyDetails}
      />
      <PrivateRoute
        path="/terms-conditions"
        isAllowed={isTncRouteAllowed}
        redirectTo="/account"
        component={AsyncTermsConditions}
      />
      <PrivateRoute
        path="/onboarding"
        isAllowed={() => true}
        redirectTo={redirectTo}
        component={OnboardingRoutes}
      />
      <PrivateRoute
        path="/settings/team_accounts"
        isAllowed={isSettingRouteAllowed}
        redirectTo={redirectTo}
        component={ManageStaff}
      />
      <PrivateRoute
        path="/welcome"
        isAllowed={() => AuthManager.isLoggedIn() }
        redirectTo="/account"
        component={WelcomePage}
      />
      <PrivateRoute
        path="/plans"
        isAllowed={isPlanRouteAllowed}
        redirectTo="/account"
        component={ConsultativePlan}
      />
      <PrivateRoute
        path="/assessment"
        isAllowed={isAssessmentRouteAllowed}
        redirectTo="/account"
        component={NeedAssessment}
      />
      <Route component={() => <NotFound goBack={{ redirectTo: '/account' } }/>} />
    </Switch>
    </Suspense>
  </Router>
);

const Routes = (props) => {
  setupProductRedirection(window);
  props.saveAllowedQueryParams(window.location);
  return (
    <Router basename='/'>
      <Switch>
        <Redirect exact from="/" to="/app" />
        <Route
          path='/account/service_login'
          component={ServiceLogin}
        />
        <Route
          path='/account/service_logout'
          component={ServiceLogout}
        />
        <Route
          path='/app'
          component={AppRoutes}
        />
        <Route component={() => <NotFound goBack={{ redirectTo: '/app/account' } } />} />
      </Switch>
    </Router>
  );
}

export default connect(
  null,
  (dispatch) => ({
    saveAllowedQueryParams: queryParams => dispatch(merchantActions.saveAllowedQueryParams(queryParams))
  }),
)(Routes);