export const INTEGRATION_TYPE = {
  NOT_SELECTED: "Not Selected",
  THIRD_PARTY: "ThirdParty",
  TOOLS: "Tools",
};

export const DOMAIN_STATUS = { ACTIVE: "active", INACTIVE: "inactive" };

export const WEBSITE_APPROVAL_STATUS = {
  ERROR: "Website Error",
  OK: "Website OK",
  IN_PROGRESS: "Verification in Process",
  PENDING: "Pending",
  RESUMBIT_DETAILS: "Resubmit Website details",
};
