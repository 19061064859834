import IDs from "@constants/componentIds";
import { BANK_STATUS } from "@constants/bankConstants";
import { WEBSITE_APPROVAL_STATUS } from "@constants/websiteDetailsConstants";
import { INTEGRATION_TYPE } from "../../constants/websiteDetailsConstants";
import { MERCHANT_DOC_STATUS } from "../../constants/manualUploadConstants";
import { getSkippedSteps } from 'merchant-status-timeline';

// merchant-statuses
export const getMerchantStatuses = (merchantDetails) => {
  const isReKYC = merchantDetails?.re_kyc_required
  const merchantStatuses = merchantDetails?.merchant_statuses || [];
  const AgreementDetails = merchantStatuses.find(v => v.status_type === 'Agreement') || {};
  const attachedConfigs = merchantDetails?.attached_configs || [];
  const skippedStepsList = getSkippedSteps({ attachedConfigs }) || [];
  return ({
    pan: {
      filled: merchantDetails?.pancard_number,
      verified: merchantDetails?.pan_verification_status === BANK_STATUS.VERIFIED
    },
    businessDetails: {
      completed: (
        merchantDetails?.business_category_id &&
        merchantDetails?.business_sub_category_id &&
        merchantDetails?.monthly_expected_volume
      )
    },
    bankDetails: {
      verified: merchantDetails?.bank_detail?.bank_verification_status == BANK_STATUS.VERIFIED,
      filled: merchantDetails?.bank_detail,
      uploadedProof: merchantDetails?.bank_detail?.bank_verification_proof
    },
    website: {
      completed: (
        merchantDetails?.integration_type === INTEGRATION_TYPE.TOOLS ||
        merchantDetails?.website_detail?.website_approval_status === WEBSITE_APPROVAL_STATUS.OK
      )
    },
    account: {
      created: merchantDetails?.account_uuid
    },
    signingAuthority: {
      completed: merchantDetails?.account?.signatory_contact_details?.length || skippedStepsList.indexOf(IDs.signingAuthority.default) >= 0,
    },
    kyc: {
      addressConfirmed: merchantDetails?.registration_address,
      notManual: merchantDetails?.kyc_status?.kyc_status === "COMPLETED",
    },
    documents: {
      verified: merchantDetails?.document_status === "Docs Approved",
    },
    serviceAgreement: {
      generated: isReKYC ? false : AgreementDetails.status_value !== 'Not Generated',
      esigned: isReKYC ? false : AgreementDetails.status_value == 'Approved' || merchantDetails?.is_service_agreement_esigned,
    },
    isReKYC,
    skippedStepsList,
  })
}

// pan-details
const panStepProps = (merchantDetails) => {
  const { serviceAgreement: { esigned }, isReKYC } = getMerchantStatuses(merchantDetails);
  return {
    completed: merchantDetails && merchantDetails.pan_verification_status === BANK_STATUS.VERIFIED,
    incomplete: !(merchantDetails && merchantDetails.pancard_number && merchantDetails.business_entity_id),
    filled: merchantDetails && !!merchantDetails.pancard_name && !!merchantDetails.pancard_number,
    editable: !esigned,
    locked: isReKYC,
  };
};

// business-detila
const businessDetailsStepProps = (merchantDetails) => {
  const { serviceAgreement: { generated }, isReKYC } = getMerchantStatuses(merchantDetails);
  const completed =
    !!merchantDetails &&
    !!merchantDetails.business_category_id &&
    !!merchantDetails.business_sub_category_id &&
    !!merchantDetails.monthly_expected_volume && (
      merchantDetails.ubo_exist ? merchantDetails?.ultimate_beneficiaries?.length > 0 : true
    );

  return {
    completed,
    incomplete: isReKYC ? !(completed && merchantDetails?.updatedBusinessDetails) : !completed,
    editable: !generated,
    locked: !panStepProps(merchantDetails).completed,
  };
};

// bank-details
const bankDetailsStepProps = (merchantDetails) => {
  const bankDetailsFilled = !!merchantDetails && !!merchantDetails.bank_detail;
  const docUploaded = bankDetailsFilled && merchantDetails.bank_detail.bank_verification_proof;
  const bankNotVerified =
    bankDetailsFilled && merchantDetails.bank_detail.bank_verification_status !== BANK_STATUS.VERIFIED;
  const { serviceAgreement: { generated }, isReKYC } = getMerchantStatuses(merchantDetails);
  return {
    completed: bankDetailsFilled && merchantDetails.bank_detail.bank_verification_status === BANK_STATUS.VERIFIED,
    filled: !!merchantDetails && !!merchantDetails.bank_detail,
    incomplete: !bankDetailsFilled || (bankDetailsFilled && !docUploaded && bankNotVerified),
    editable: !generated,
    error: bankDetailsFilled && merchantDetails.bank_detail.bank_verification_status !== BANK_STATUS.VERIFIED,
    locked: isReKYC || !panStepProps(merchantDetails).completed,
  };
};

// signing-authority
export const signingAuthorityStepProps = (merchantDetails) => {
  const cinRequiredEntityTypes = ['Public Limited', 'Private Limited', 'LLP', 'One Person Company'];
  const signingAuthorityDetails = merchantDetails?.account?.signatory_contact_details?.[0];
  let completed = (
    signingAuthorityDetails?.name &&
    signingAuthorityDetails?.email &&
    signingAuthorityDetails?.pancard_number
  );
  const accountNotCreated = merchantDetails && merchantDetails.account_uuid;
  const ckycOrAadharCompleted = merchantDetails?.kyc_status?.kyc_status === "COMPLETED";
  const { serviceAgreement: { generated }, documents: { verified: documentsApproved }, isReKYC } = getMerchantStatuses(merchantDetails);
  const entityType = merchantDetails?.business_entity?.name;
  if (cinRequiredEntityTypes.includes(entityType))
    completed = completed && signingAuthorityDetails?.cin_number

  const attachedConfigs = merchantDetails?.attached_configs || [];
  const skippedStepsList = getSkippedSteps({ attachedConfigs }) || [];
  return {
    completed: completed || skippedStepsList.indexOf(IDs.signingAuthority.default) >= 0,
    incomplete: isReKYC ? !(completed && merchantDetails?.updatedSigningAuthorityDetails) : !completed,
    editable: !ckycOrAadharCompleted && !documentsApproved && !generated,
    locked: !panStepProps(merchantDetails).completed || !accountNotCreated,
  };
};

// website-details
const websiteDetailsStepProps = (merchantDetails) => {
  let website_approval_status = null;
  let completed = false;
  let incomplete = true;
  if (merchantDetails) {
    if (merchantDetails.integration_type === INTEGRATION_TYPE.NOT_SELECTED) {
      completed = false;
      incomplete = true;
    }
    else if (merchantDetails.integration_type === INTEGRATION_TYPE.THIRD_PARTY) {
      if (merchantDetails.website_detail) {
        ({ website_approval_status } = merchantDetails.website_detail);
        if (website_approval_status == WEBSITE_APPROVAL_STATUS.PENDING) {
          completed = false;
          incomplete = false;
        } else if (website_approval_status == WEBSITE_APPROVAL_STATUS.IN_PROGRESS) {
          completed = false;
          incomplete = false;
        } else if (website_approval_status == WEBSITE_APPROVAL_STATUS.OK) {
          completed = true;
          incomplete = false;
        } else if (website_approval_status == WEBSITE_APPROVAL_STATUS.ERROR) {
          completed = false;
          incomplete = true;
        } else {
          completed = false;
          incomplete = true;
        }
      }
      else {
        completed = false;
        incomplete = true;
      }
    }
    else if (merchantDetails.integration_type === INTEGRATION_TYPE.TOOLS) {
      completed = true;
      incomplete = false;
    }
    else {
      completed = false;
      incomplete = true;
    }
  }
  else {
    completed = false;
    incomplete = true;
  }

  const { serviceAgreement: { generated }, isReKYC } = getMerchantStatuses(merchantDetails);
  return {
    completed: completed,
    incomplete: incomplete,
    editable: !generated,
    locked: isReKYC || !panStepProps(merchantDetails).completed,
  };
};

// kyc
const kycStepProps = (merchantDetails) => {
  const addressConfirmed = merchantDetails?.registration_address;
  const ckycOrAadharCompleted = merchantDetails?.kyc_status?.kyc_status === "COMPLETED";
  const { serviceAgreement: { generated } } = getMerchantStatuses(merchantDetails);
  const document_status = merchantDetails?.document_status;
  const documentsApproved = document_status == MERCHANT_DOC_STATUS.DOCUMENT_APPROVED;
  return {
    completed: documentsApproved || (ckycOrAadharCompleted && addressConfirmed),
    incomplete: !(ckycOrAadharCompleted && addressConfirmed) && !documentsApproved && !merchantDetails?.manualAddress,
    editable: !additionalDocumentsStepProps(merchantDetails).completed && !generated,
    filled: documentsApproved || ckycOrAadharCompleted,
    locked: !signingAuthorityStepProps(merchantDetails).completed,
  };
};

// additional documents
const additionalDocumentsStepProps = (merchantDetails) => {
  const document_status = merchantDetails?.document_status;
  const { completed: esignCompleted } = serviceAgreementStepProps(merchantDetails);
  const addressConfirmed = merchantDetails?.registration_address;
  let incomplete = true;
  if (document_status == MERCHANT_DOC_STATUS.DOCUMENT_RECEIVED || document_status == MERCHANT_DOC_STATUS.DOCUMENT_APPROVED) {
    incomplete = false;
  }
  const { skippedStepsList, bankDetails } = getMerchantStatuses(merchantDetails);
  const addressNotRequired = skippedStepsList.indexOf(IDs.kyc.default) >= 0;
  return {
    completed: document_status == MERCHANT_DOC_STATUS.DOCUMENT_APPROVED,
    incomplete: incomplete,
    editable: !esignCompleted,
    locked: !(panStepProps(merchantDetails).completed && signingAuthorityStepProps(merchantDetails).completed && bankDetails?.filled),
    hidden: addressNotRequired ? false : !addressConfirmed,
  };
};

// service agreement
const serviceAgreementStepProps = (merchantDetails) => {
  const { serviceAgreement: { esigned }, isReKYC } = getMerchantStatuses(merchantDetails);
  // const {
  //   businessDetails: { completed: businessDetailsCompleted },
  //   website: { completed: websiteDetailsCompleted },
  //   documents: { verified: docsVerified },
  // } = getMerchantStatuses(merchantDetails);
  return {
    completed: esigned,
    incomplete: !esigned,
    // locked: !(businessDetailsCompleted && websiteDetailsCompleted && docsVerified),
    locked: isReKYC,
  };
};

//  Selectors
export const selectors = {
  getStepProps: (merchantDetails, componentID) => {
    switch (componentID) {
      case IDs.panDetails.default:
        return panStepProps(merchantDetails);
      case IDs.businessDetails.default:
        return businessDetailsStepProps(merchantDetails);
      case IDs.bankDetails.default:
        return bankDetailsStepProps(merchantDetails);
      case IDs.websiteDetails.default:
        return websiteDetailsStepProps(merchantDetails);
      case IDs.signingAuthority.default:
        return signingAuthorityStepProps(merchantDetails);
      case IDs.kyc.default:
        return kycStepProps(merchantDetails);
      case IDs.additionalDocuments.default:
        return additionalDocumentsStepProps(merchantDetails);
      case IDs.serviceAgreement.default:
        return serviceAgreementStepProps(merchantDetails);
      default:
        return { completed: false, editable: true };
    }
  },
};
