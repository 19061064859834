/*eslint-disable*/
/*
 *
 * Utilities
 *
 */

import isArray from 'lodash/isArray';
import isObjectLike from 'lodash/isObjectLike';
import isPlainObject from 'lodash/isPlainObject';
import transform from 'lodash/transform';
import camelCase from 'lodash/camelCase';
import set from 'lodash/set';
import head from 'lodash/head';
import isString from 'lodash/isString';
import { config, RedirectionService as Redirection } from 'hub-redux-auth';
import API_ENDPOINTS from './apiConfig';
import RestClient from './RestClient';
import AuthManager from './AuthManager';
import cleverTap from '../services/cleverTap';
import * as EVENT from '../constants/cleverTapEvents';
import careHost from '../utils/careHost';

import {
  INDIVIDUAL,
  PATNERSHIP,
  PROPRIETORSHIP,
  LLP,
} from '../constants/profileConstants';
import 'url-search-params-polyfill';
import { RedirectionService } from 'hub-redux-auth';
import { BUSINESS_ENTITIES,
  BUSINESS_ASSOCIATION_TYPE } from '../constants/appConstants';
import DocumentUploadListItem from '../components/DocumentUploadListItem';

/**
 * utility for conditionally joining classNames together
 * @param  {Object} conditionalClass with property as css class and value as boolean
 * @return {String} return joining css classnames whose value is true in conditionalClass
 */
export function classNames(conditionalClass = {}) {
  return Object.keys(conditionalClass)
    .filter(cssClass => conditionalClass[cssClass])
    .join(' ');
}


function createIteratee(converter, selfFn) {
  return (result, value, key) =>
    set(result, converter(key), isObjectLike(value) ? selfFn(value) : value);
}

export function skipToChooseWebsiteName({ user }) {
  const productLoginUrl = document.getElementById('product-login-url') ?
    document.getElementById('product-login-url').getAttribute('data-url') : null;
  const turl = new URL(productLoginUrl);
  window.location = `${turl.protocol}//${turl.hostname}/merchant-dashboard/#/website-name?authToken=${
    AuthManager.getFullToken()}&merchantId=${user.mid}`
}

/**
 * utility for converting nested object keys to camelCase
 * @param  {Object} obj plain object literal or array
 * @return {String} return object with camelcase keys
 */
export function toCamelCase(obj) {
  if (isArray(obj)) return obj.map(toCamelCase);
  if (isPlainObject(obj)) return transform(obj, createIteratee(camelCase, toCamelCase));
  return obj;
}


/**
 * utility for handling user redirection after getting email from user
 * @param  {Object} browserHistory reference of history object
 * @param  {Object} user object containing user data
 * @param  {String} userEmail in string
 * @return {None}
 */
export function handleUserAccountRedirection(browserHistory, user, userEmail, paramsString = '') {
  const isUserExits = user && Object.keys(user).length;

  let locationData = {};
  if (isUserExits) {
    const accessToken = AuthManager.getHubToken(user);
    const {
      mobile,
      'mobile-verified': isMobileVerified,
      'email-verified': isEmailVerified,
      'is-password-set': isPasswordSet,
    } = user.attributes;
    if (mobile) {
      cleverTap.event(EVENT.LOGIN_FLOW_INITIATED);
      if (isMobileVerified) {
        /**
         * if user has mobile & its verfied then go to login page
         * Note that state is history state is required in login
         * as email will be used for login using password and
         * accesstoken will be used for login using m-otp or e-otp
         */
        if (!isEmailVerified && !isPasswordSet) {
          let redirectTo = ['/onboarding'];
          if (!isPasswordSet) {
            redirectTo = ['/account/setpassword', ...redirectTo];
          }
          locationData = {
            pathname: '/account/mobile/otp',
            state: {
              accessToken,
              userEmail,
              redirectTo,
              userMobile: mobile,
              paramsString,
            },
          };
        } else {
          locationData = {
            pathname: '/account/login', // goto login
            state: {
              accessToken, // will be used for login using otp
              userEmail,
              userMobile: mobile,
              isPasswordSet,
              paramsString,
              isEmailVerified,
            },
          };
        }
      } else {
        /**
         * here when user has mobile but not verified so signin
         * using mobile first. After signin mobile will be verified
         * and after mobile verification go to email otp route.
         * redirectTo array contains next route to go after successfull verification
         */
        let redirectTo = ['/onboarding'];
        if (!isPasswordSet) {
          redirectTo = ['/account/setpassword', ...redirectTo];
        }
        if (!isEmailVerified) {
          redirectTo = ['/account/email/otp', ...redirectTo];
        }
        locationData = {
          pathname: '/account/mobile/otp',
          state: {
            accessToken,
            userEmail,
            redirectTo,
            userMobile: mobile,
            paramsString,
          },
        };
      }
    } else {
      /**
       * here when user exists but not have mobile so firstly login using
       * email then go to registeration page and take mobile no then verify
       * mobile otp using full token received after login to append mobile no in user
       */
      let redirectTo;
      if (!isPasswordSet) {
        redirectTo = ['/account/register', '/account/mobile/otp', '/account/setpassword', '/onboarding'];
      } else {
        redirectTo = ['/account/register', '/account/mobile/otp', '/onboarding'];
      }
      locationData = {
        pathname: '/account/email/otp',
        state: {
          accessToken,
          userEmail,
          redirectTo,
          userName: user.attributes['display-name'],
          paramsString,
        },
      };
    }
  } else {
    /**
     * here when user is new so first take details & register user then login
     * using mobile and verify email using full token received after login
     * after email verification go to onboarding
     */
    cleverTap.event(EVENT.CREATE_ACCOUNT_INITIATED);
    locationData = {
      pathname: '/account/register',
      state: {
        userEmail,
        paramsString,
        newUser: true,
        redirectTo: ['/account/mobile/otp', '/account/email/otp', '/account/setpassword', '/onboarding'],
      },
    };
  }
  browserHistory.push(locationData);
}

/*
 * converts bytes to killobytes
 * @param  {Number} bytes
 * @param  {Number} decimals: decimal point limit
 * @return {Number} KB in number
 */
export function bytesToKB(bytes, decimals = 0) {
  if (!bytes) {
    return 0;
  }
  return parseFloat((bytes / (1024 ** 1)).toFixed(decimals));
}


/**
 * utility to get localstoragekey for app tour
 * @param {Number/String} usually merchant id
 * @param {Number/String}
 * @return {String} local storage key for item
 */
function getItemKey(param, item) {
  return `_${param}__${item}`;
}
/**
 * check whether user has completed Item
 * @param {String} name of the key
 * @return {bool} true if Item is completed else false
 */
export function isItemCompleted(item) {
  const merchantId = AuthManager.getUserId();
  if (!window.localStorage.getItem(getItemKey(merchantId, item))) {
    return false;
  }
  return true;
}
/**
 * sets the flag for Item to true for completed
 * @param {String} name of the key
 * @return {undefined}
 */
export function setItemToCompleted(item) {
  const merchantId = AuthManager.getUserId();
  window.localStorage.setItem(getItemKey(merchantId, item), true);
}

export function removeStoredItem(item) {
  const merchantId = AuthManager.getUserId();
  window.localStorage.removeItem(getItemKey(merchantId, item));
}

/* callback invoked by hub-redux-auth with detail of foc response when user enters email
  can be used as middleware to configure behaviour of hub-redux-auth
  eg: disable password expired page.
 */
export async function checkUserForProducts(userData, email = null) {
  try {
    const user = userData[0];
    const response = await RestClient.get(API_ENDPOINTS.PRODUCT.USER_PRODUCTS({ uuid: user.id, email: email }));
    if (!response.products || response.products.length === 0) {
      config.set('skip_password_expired_screen', true);
    } else {
      config.set('skip_password_expired_screen', false);
    }
    config.set('user_registered_products', response.products || []);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export function getRedirectUrl(user, productLoginUrl, signUpFlag) {
  let finalProductLoginUrl = null;
  // check only in case of OnePayU pack only && productLoginUrl is not of care
  if (config.get('product').toUpperCase() === 'PAYU' && !productLoginUrl.includes(careHost())){
    const moneyProductHost = config.get('moneyProductHost'),
      nowProductHost = config.get('nowProductHost');
    if (user.product.toUpperCase() === 'PAYUMONEY'){
      /**
       * if merchant is of PUM product & redirect_to url i.e productLoginUrl is not of PUM dashboard
       * then redirect the merchant to PUM dashboard
       */
      finalProductLoginUrl = productLoginUrl.includes(moneyProductHost) || Redirection.validateURL(productLoginUrl)  
        ? productLoginUrl
        : config.get('moneyRedirectionUrl');
    } else if (user.product.toUpperCase() === 'PAYUNOW'){
      /**
       * if merchant is of PayUNow product & redirect_to url i.e productLoginUrl is not of PayUNow dashboard
       * then redirect the merchant to PayUNow dashboard
       */
      finalProductLoginUrl = productLoginUrl.includes(nowProductHost)
        ? productLoginUrl
        : config.get('nowRedirectionUrl');
    }
  }
  signUpFlag = signUpFlag === undefined ? user.new_merchant : signUpFlag;
  const productURLObject = new URL(finalProductLoginUrl || productLoginUrl);
  const params = new URLSearchParams(productURLObject.search);
  params.set('authToken', AuthManager.getFullToken());
  params.set('merchantId', user.mid);
  params.set('profileCompleted', user.profile_completed);
  /*
   * signup=true was use to show welcome page on money end, but now welcome page has been hosted on onboarding
   * params.set('signup', signUpFlag);
   * to be removed in next session create release
   */
  /**
   * no need to check productLoginUrl product here because
   * here it only checks if productLoginUrl contains onboarding or not
   */
  const queryParams = !productLoginUrl.toLowerCase().includes('onboarding') ? params.toString() : encodeURIComponent(params.toString());
  return `${productURLObject.href.split('?')[0]}?${queryParams}`;
}

export function hasNowMerchantCameToMoney(userBusinessOrigin, userProduct, appProduct) {
  return (
    userBusinessOrigin === 'PayUnow' &&
    userProduct === 'PayUnow' &&
    (appProduct === 'MONEY' || appProduct === 'PAYU')
  );
}

/*
  returns the holder name and business name for payunow merchant based on filing status
*/
export function getBusinessAndAccountHolderNameForNow(values, filingStatus) {
  let businessName = values.pancard_name;
  let holderName;
  switch (filingStatus) {
    case INDIVIDUAL:
    case PATNERSHIP:
    case LLP: {
      holderName = values.pancard_name;
      break;
    }
    case PROPRIETORSHIP: {
      holderName = values.business_name;
      businessName = values.business_name;
      break;
    }
    default: {
      holderName = values.holder_name;
      break;
    }
  }
  return { businessName, holderName };
}

export async function notifyError(error, backTrace) {
  const params = {
    name: error,
    backTrace,
    reduxState: window.__redux_store_dump && window.__redux_store_dump.getState(),
  };
  const endpoint = API_ENDPOINTS.ERROR.NOTIFY_BACKEND;
  try {
    await RestClient.post(endpoint, { exception: params });
  } catch (err) {
    const body = await err.response;
    console.error(body);
  }
}

/**
  * Formats server side errors  according to key provided
*/
export function formatErrors(requestBody, msgKey) {
  if (requestBody && requestBody.messages) {
    const { messages } = requestBody;
    const value = messages[msgKey];
    if (value && !Array.isArray(value)) {
      return [value];
    }
    return value || Object.values(messages);
  }
  if (requestBody && msgKey) {
    return requestBody[msgKey];
  }
  return ['Some error occurred. Please try again later'];
}

export function isEmailVerified(hubUserData, withEmail) {
  if (!withEmail) {
    return false;
  }
  const userEmail = head(hubUserData.attributes.emails.filter(email => email.email === withEmail));
  return userEmail.verified;
}

export function isPasswordSet(hubUserData) {
  return hubUserData.attributes['is-password-set'];
}

export function isFirstNameSet(hubUserData) {
  const userAttributes = hubUserData.attributes;
  const firstName = userAttributes['first-name'];
  return !!firstName;
}

export function isLastNameSet(hubUserData) {
  const userAttributes = hubUserData.attributes;
  const lastName = userAttributes['last-name'];
  return !!lastName;
}

export function isDisplayNameSetOnHUB(hubUserData) {
  const userAttributes = hubUserData.attributes;
  const displayName = userAttributes['display-name'];
  return !!displayName;
}

export function isNameSetOnHub(hubUserData) {
  return isFirstNameSet(hubUserData) || isDisplayNameSetOnHUB(hubUserData);
}

/**
 * @todo Write the documentation.
 * @todo Implement this function, in hub-redux-auth
 */
export const userFriendlyErrorMessage = (errorMessage) => {
  const errorString = typeof errorMessage === "string" ? errorMessage : errorMessage.join(' ');
  if (errorString === 'User account is now locked' ||
    errorString === 'User account is locked' ||
    errorString.match(/user found by [a-z\-0-9]+ is locked./)) {
    return 'User is locked, please check email/sms for unlock instruction.';
  }
  if (errorString === 'Otp is incorrect, 1 attempts remaining' ||
    errorString.match(/Otp code [0-9]* is incorrect. 1 attempts remaining./)) {
    return 'Last attempt left! If you enter incorrect OTP, your account will be blocked.';
  }
  return errorString;
};

export const setupProductRedirection = (props) => {
  const { location } = props;
  RedirectionService.setURL(location.search, config.get('productRedirectUrl'));
};

export const urlParams = data =>
  Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&');

export function getBusinessEntityName(businessEntityId, businessEntities) {
  const [businessEntity] = businessEntities.filter(entity => entity.id === businessEntityId);
  return businessEntity
    ? businessEntity.name
    : null;
}

export function getDocumentPath(kycDoc) {
  if (kycDoc && kycDoc.processed_document) {
    return kycDoc.processed_document.path;
  }
  return null;
}

export const getBusinessEntityPersonType = (businessEntityName) => {
  switch (businessEntityName) {
    case BUSINESS_ENTITIES.PARTNERSHIP:
      return BUSINESS_ASSOCIATION_TYPE.PARTNERSHIP;
    case BUSINESS_ENTITIES.LLP:
      return BUSINESS_ASSOCIATION_TYPE.LLP;
    case BUSINESS_ENTITIES.PRIVATE_LIMITED:
      return BUSINESS_ASSOCIATION_TYPE.PRIVATE_LIMITED;
    case BUSINESS_ENTITIES.PUBLIC_LIMITED:
      return BUSINESS_ASSOCIATION_TYPE.PUBLIC_LIMITED;
    case BUSINESS_ENTITIES.TRUST:
      return BUSINESS_ASSOCIATION_TYPE.TRUST;
    case BUSINESS_ENTITIES.SOCIETY:
      return BUSINESS_ASSOCIATION_TYPE.SOCIETY;
    default:
      return 'Signing Authority';
  }
}

export function formatSubmittedDocResponseUtil(response) {
  const data = response.reduce((map, obj) => {
    map[obj.document_category_id] = obj;
    return map;
  }, {});
  return data;
}

export const validateFile = (size, ext) => {
  const hasValidExtenstion = DocumentUploadListItem.allowedFileExtensions.includes(ext.toLowerCase(), );
  const maxFileSizeLimit =
      DocumentUploadListItem.maxFileSize;
  const hasValidSize = size < maxFileSizeLimit * 1024;
  const errors = [];
  if (!hasValidExtenstion) {
    errors.push('Error: only PDF, PNG and JPG files are allowed');
  }
  if (!hasValidSize) {
    errors.push(`Error: File size exceeded, should be less than ${maxFileSizeLimit}MB`, );
  }
  return errors;
},

  /*
  * converts active modal json errors to component consumable
  * @param  {errorMessage} Erro json object
  * @return {fromattedErrorMessage} concatenated error messages
  */
  formatActiveModalErrors = (errorMessage) => {
    const message = errorMessage.errors || errorMessage.error;
    if (Array.isArray(message)) {
      return message.join();
    } else if (isString(message)) {
      return message;
    } else {
      const messageList = Object.keys(message).reduce((messages, key) => {
        if (Array.isArray(message[key])) {
          messages.push(message[key].join());
        }
        return messages;
        },
        []
      );
      return messageList.join();
    }
  },

  /*
  * converts DateString to MonthName Day Year
  * @param  {dateString} string
  * @return {FormattedDate} MonthName Day Year
  */
  formatDate = (dateString) => {
    const date = new Date(Date.parse(dateString)),
      options = { day: 'numeric',
        month: 'short',
        year: 'numeric' };
    return date.toLocaleString('en', options)
  },

checkPermissionOnElement = (permissionString) => {
  let aclPermissions = sessionStorage.user && JSON.parse(sessionStorage.user).aclPermissions;
  let scopeFound = false;
  if(aclPermissions) {
    let permissionsList = aclPermissions.acl_role && aclPermissions.acl_role.acl_permissions;
    if(permissionsList && permissionsList.length > 0) {
      permissionsList.forEach((ele) => {
        if(ele.name === permissionString || ele.scopes.includes(permissionString)) {
          scopeFound = true;
        }
      })
      return scopeFound;
    }
  } else return scopeFound;
},

getCurrentTimeIST = () => {
    const currentOffset = new Date().getTimezoneOffset(),
    istOffset = 330,
    tuningParameter = (istOffset + currentOffset) * 60000;
    return new Date(new Date().getTime() + tuningParameter);
},

getSecondsToHoursMinutes = (seconds) => {
    let totalTime;
    const hours = Math.floor(seconds / 3600),
      minutes = Math.floor(seconds / 60);
    if (hours > 0) {
      totalTime = `${hours} hours`;
    } else if (minutes > 0) {
      totalTime = `${minutes} minutes`;
    } else {
      totalTime = `${seconds} seconds`;
    }
    return totalTime;
};

/**
 * function to create form for redirection with post params
 */
const createRedirectionForm = (authToken, merchantId, redirectionUrl, redirectToUrl) => {
  const authTokenInput = document.createElement('INPUT'),
    merchantIdInput = document.createElement('INPUT'),
    redirectionForm = document.createElement('FORM');
  redirectionForm.setAttribute('id', 'redirection-form');
  redirectionForm.setAttribute('action', redirectionUrl);
  redirectionForm.setAttribute('method', 'POST');
  redirectionForm.setAttribute('style', 'display:none');
  document.body.appendChild(redirectionForm);
  authTokenInput.setAttribute('type', 'TEXT');
  authTokenInput.setAttribute('name', 'authToken');
  authTokenInput.setAttribute('value', authToken);
  merchantIdInput.setAttribute('type', 'TEXT');
  merchantIdInput.setAttribute('name', 'merchantId');
  merchantIdInput.setAttribute('value', merchantId);
  redirectionForm.appendChild(authTokenInput);
  redirectionForm.appendChild(merchantIdInput);
  if (redirectToUrl){
    const redirectToInput = document.createElement('INPUT');
    redirectToInput.setAttribute('type', 'TEXT');
    redirectToInput.setAttribute('name', 'redirectTo');
    redirectToInput.setAttribute('value', redirectToUrl);
    redirectionForm.appendChild(redirectToInput);
  }
  redirectionForm.submit();
};

/**
 * function to submit redirection form with authToken & mid in post params
 */
export function onRedirectionCLick(redirectToUrl){
  const user = AuthManager.getUser();

  if (user && user.dashboard_preference === 'one_dashboard') {
    let oneDashboardUrl = config.get('oneDashboardUrl');
    let vistaDashboardUrl = config.get('vistaDashboardUrl');
    oneDashboardUrl = new URL(oneDashboardUrl);
    vistaDashboardUrl = new URL(vistaDashboardUrl);
    const redirectToUrlHostname = redirectToUrl ? (new URL(redirectToUrl)).hostname : ''
    if (redirectToUrl && (redirectToUrlHostname == oneDashboardUrl.hostname || redirectToUrlHostname == vistaDashboardUrl.hostname)) {
      window.location.href = redirectToUrl;
    } else {
      window.location.href = oneDashboardUrl;
    }
  } else {
    const bizRedirectionUrl = config.get('bizRedirectionUrl'),
        fullToken = AuthManager.getFullToken();
    /* redirect the merchant to logout path if any one of three full_token, user.mid , redirection_url is not present */
    if (!fullToken || !user || !user.mid || !bizRedirectionUrl){
      window.location.href = '/account/service_logout';
    }
    createRedirectionForm(fullToken, user.mid, bizRedirectionUrl, redirectToUrl);
  }
}

/**
 * function to return careUrl for any queries
 */
export function getContactUsLink(queryParams, authParams){
  const user = AuthManager.getUser();
  let careUrl = `${careHost()}?${queryParams}${authParams ? `&${authParams}` : ''}`;
  if (user && user.product.toUpperCase() === 'PAYUMONEY'){
    const productHost = config.get('moneyProductHost') || config.get('productHost') || null;
    careUrl = `${productHost}/contact-us.html?${queryParams}${authParams ? `&${authParams}` : ''}`;
  } else if (user && user.product.toUpperCase() === 'PAYUNOW'){
    const productHost = config.get('nowProductHost') || config.get('productHost') || null;
    careUrl = `${productHost}/contact-us.html?${queryParams}${authParams ? `&${authParams}` : ''}`;
  }
  return careUrl;
}
