import axios from 'axios';
import { takeLatest, call, put, select } from 'redux-saga/effects'; 
import API_ENDPOINTS from '@services/apiConfig';
import AuthManager from "@services/AuthManager";

import { merchantSelectors, hubSelectors } from '.';

//  Action types
export const actionTypes = {
  fetchHubToken: 'fetchHubToken/INITIATE',
  fetchHubTokenSuccess: 'fetchHubToken/SUCCESS',
  fetchHubTokenFailed: 'fetchHubToken/FAILED',
  verifyPassword: 'verifyPassword/INITIATE',
  verifyPasswordSuccess: 'verifyPassword/SUCCESS',
  verifyPasswordFailed: 'verifyPassword/FAILED',
  findOrCreateEmail: 'findOrCreateEmail/INITIATE',
  findOrCreateEmailSuccess: 'findOrCreateEmail/SUCCESS',
  findOrCreateEmailFailed: 'findOrCreateEmail/FAILED',
};

const initialState = {
  data: {},
  verifyPassword: {
    processing: false,
    response: null,
    errResponse: null,
  },
  findOrCreate: {
    email: {
      response: null,
    },
  },
};

//  Reducer
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetchHubTokenSuccess:
      return { ...state, data: action.payload }
    case actionTypes.verifyPassword:
      return { ...state, verifyPassword: { ...initialState.verifyPassword, processing: true } };
    case actionTypes.verifyPasswordSuccess:
      return { ...state, verifyPassword: { processing: false, response: action.payload, errResponse: null, } };
    case actionTypes.verifyPasswordFailed:
      return { ...state, verifyPassword: { processing: false, response: null, errResponse: action.payload, } };
    case actionTypes.findOrCreateEmailSuccess:
    case actionTypes.findOrCreateEmailFailed:
      return { ...state, findOrCreate: { ...state.findOrCreate, email: { response: action.payload } } };
    default: {
      return state;
    }
  }
};

//  Selectors
export const selectors = {
  getHubToken: (state) => state.onboardingApp.hub.data.access_token,
  verifyPasswordProcessing: (state) => state.onboardingApp.hub.verifyPassword?.processing,
  verifyPasswordErr: (state) => state.onboardingApp.hub.verifyPassword?.errResponse,
  verifyPasswordAccessToken: (state) => state.onboardingApp.hub.verifyPassword?.response?.access_token,
  getfindOrCreateEmailResponse: (state) => state.onboardingApp.hub.findOrCreate?.email?.response,
};


//  Actions
export const actions = {
  fetchHubToken: () => ({ type: actionTypes.fetchHubToken }),
  verifyPassword: ({ password, callbackAction }) => ({ type: actionTypes.verifyPassword, payload: { password, callbackAction } }),
  findOrCreateEmail: () => ({ type: actionTypes.findOrCreateEmail }),
};

//  Workers
function* fetchHubTokenWorker() {
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.HUB.TOKEN,
      {
        client_id: process.env.HUB_CLIENT_ID,
        grant_type: 'client_credentials',
        scope: "find_or_create send_sign_in_otp verify_sign_in_otp partner_permissions_and_credentials"
      },
    ) || {};
    yield put({ type: actionTypes.fetchHubTokenSuccess, payload: response.data });
  } catch {
    yield put({ type: actionTypes.fetchHubTokenFailed, payload: 'send-otp-error' });
  }
}

function* verifyPasswordWorker(action) {
  try {
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.HUB.VERIFY_PASSWORD,
      {
        client_id: process.env.HUB_CLIENT_ID,
        grant_type: 'password',
        scope: 'send_verification_otp verify_verification_otp',
        username: yield select(merchantSelectors.getMerchantEmail),
        password: action?.payload?.password,
      },
      { skipLogout: true },
    );
    yield put({ type: actionTypes.verifyPasswordSuccess, payload: response?.data });
    if (action?.payload?.callbackAction) {
      action?.payload?.callbackAction(response);
    }
  } catch (err) {
    const errResponse = yield err.response;
    yield put({
      type: actionTypes.verifyPasswordFailed,
      payload: {
        attemptsRemaining: errResponse?.data?.additional_data?.user?.remaining_attempts || 'NA',
        error: errResponse?.data?.additional_data?.error || 'NA',
        errorDescription: errResponse?.data?.additional_data?.error_description || 'NA',
      }
    });
  }
};

function* findOrCreateEmailWorker() {
  try {
    const { email } = AuthManager.getUser() || {};
    const response = yield call(
      [axios, 'post'],
      API_ENDPOINTS.HUB.FIND_OR_CREATE,
      { email },
      { token: yield select(hubSelectors.getHubToken) },
    );
    yield put({ type: actionTypes.findOrCreateEmailSuccess, payload: response?.data });
  } catch {
    yield put({ type: actionTypes.findOrCreateEmailFailed });
  }
}


//  Sagas
export function* saga() {
  yield takeLatest(actionTypes.fetchHubToken, fetchHubTokenWorker);
  yield takeLatest(actionTypes.verifyPassword, verifyPasswordWorker);
  yield takeLatest(actionTypes.findOrCreateEmail, findOrCreateEmailWorker);
}