import { takeLatest, call, put, select } from 'redux-saga/effects';
import API_ENDPOINTS from '@services/apiConfig';
import axios from 'axios';
import AuthManager from '@services/AuthManager';
import { dialogActions, otpActions, merchantSelectors, merchantActions, uiActions, toastActions, commentsActions } from '.';

export const actionTypes = {
  sendOtp: 'websiteDetails/SEND_OTP',
  checkWebsiteLiveliness: 'websiteDetails/CHECK_LIVELINESS',
  checkFailed: 'websiteDetails/CHECK_FAILED',
  checkFulfilled: 'websiteDetails/CHECK_FULFILLED',
  submitWebsiteStatus: 'websiteDetails/SUBMIT_WEBSITE_STATUS',
  saveWebsiteUUID: 'websiteDetails/SAVE_WEBSITE_UUID',
  saveUpdatedWebsiteDetails: 'websiteDetails/SAVE_UPDATED_WEBSITE_DETAILS',
  setLivelinessStatus: 'websiteDetails/SET_LIVELINESS_STATUS',
  fetchCurrentWebsiteDetails: 'websiteDetails/FETCH_CURRENT_WEBSITE_DETAILS',
  updateMerchantIntegrationType: "websiteDetails/UPDATE_MERCHANT_INTEGRATION_TYPE"
};

export const actions = {
  sendOtp: (websiteDetails) => ({
    type: actionTypes.sendOtp,
    payload: websiteDetails,
  }),
  checkWebsiteLiveliness: ({comment, file, urlNotUpdated}) => ({
    type: actionTypes.checkWebsiteLiveliness,
    payload: { comment: comment, file: file , urlNotUpdated: urlNotUpdated },
  }),
  checkFulfilled: (response) => ({
    type: actionTypes.checkFulfilled,
    payload: response,
  }),
  checkFailed: (errResponse) => ({
    type: actionTypes.checkFailed,
    payload: errResponse,
  }),
  submitWebsiteStatus: (websiteStatus) => ({
    type: actionTypes.submitWebsiteStatus,
    payload: websiteStatus,
  }),
  saveWebsiteUUID: (websiteUUID) => ({
    type: actionTypes.saveWebsiteUUID,
    payload: websiteUUID,
  }),
  saveUpdatedWebsiteDetails: (updatedWebsiteDetails) => ({
    type: actionTypes.saveUpdatedWebsiteDetails,
    payload: updatedWebsiteDetails,
  }),
  setLivelinessStatus: (url_live) => ({
    type: actionTypes.setLivelinessStatus,
    payload: url_live,
  }),
  fetchCurrentWebsiteDetails: () => ({
    type: actionTypes.fetchCurrentWebsiteDetails,
  }),
  updateMerchantIntegrationType: (integration_type) => ({
    type: actionTypes.updateMerchantIntegrationType,
    payload: integration_type,
  }),
};

const initialState = {
  checkStatus: false,
  errResponse: null,
  websiteStatus: 'inactive',
  websiteUUID: null,
  url_live: false,
  updatedWebsiteDetails: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.sendOtp: {
      return {
        ...state,
      };
    }
    case actionTypes.checkWebsiteLiveliness: {
      return {
        ...state,
      };
    }
    case actionTypes.checkFulfilled: {
      return {
        ...state,
        liveliness_status: action.payload,
      };
    }
    case actionTypes.checkFailed: {
      return {
        ...state,
        fetched: false,
        errResponse: action.payload,
      };
    }
    case actionTypes.submitWebsiteStatus: {
      return { ...state, websiteStatus: action.payload };
    }
    case actionTypes.saveWebsiteUUID: {
      return { ...state, websiteUUID: action.payload };
    }
    case actionTypes.saveUpdatedWebsiteDetails: {
      return { ...state, updatedWebsiteDetails: action.payload };
    }
    case actionTypes.setLivelinessStatus: {
      return {
        ...state,
        url_live: action.payload,
      };
    }
    case actionTypes.fetchCurrentWebsiteDetails: {
      return {
        ...state,
      };
    }
    case actionTypes.updateMerchantIntegrationType: {
      return {
        ...state,
      }
    }
    default: {
      return state;
    }
  }
};

export const selectors = {
  getWebsiteStatus: (state) => state.onboardingApp.websiteDetails.websiteStatus,
  getWebsiteUUID: (state) => state.onboardingApp.websiteDetails.websiteUUID,
  getLivelinessStatus: (state) => state.onboardingApp.websiteDetails.url_live,
  getUpdatedWebsiteDetails: (state) => state.onboardingApp.websiteDetails.updatedWebsiteDetails,
};

function* otpWorker(action) {
  const merchantDetails = yield select(merchantSelectors.getMerchantDetails);
  yield put(
    otpActions.sendOTP(['email', 'sms'], merchantDetails.email ? merchantDetails.email : '', 'update_website_details', 'SignIn', 'verify-otp-website')
  );
}

function* checkLivelinessWorker(action) {
  const { comment, file, urlNotUpdated } = action.payload;
  try {
    if (!urlNotUpdated) {
      yield put(dialogActions.open('website-ws-loader'));
      const updatedUrls = yield select(selectors.getUpdatedWebsiteDetails);
      const {
        data: { website_detail },
      } = yield call([axios, 'post'], API_ENDPOINTS.ONBOARDING.LIVELINESS_CHECK(AuthManager.getMerchantUUID()), { website_detail: updatedUrls });
      yield put(actions.saveWebsiteUUID(website_detail.uuid));
    }
    if (comment || file) {
      yield put(commentsActions.submitWebisteComment({ comment, file, urlNotUpdated }))
    }
  } catch (err) {
    const errResponse = err.response;
    yield put(actions.checkFailed(errResponse));
    yield put(
      toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Could not fetch data. Refresh the page and try again.',
      })
    );
  }
}

function* fetchCurrentWebsiteDetailsWorker(action) {
  try {
    const {
      data: { website_detail },
    } = yield call([axios, 'get'], API_ENDPOINTS.ONBOARDING.LIVELINESS_CHECK(AuthManager.getMerchantUUID()));
    yield put(
      uiActions.loadUI(
        {
          website_detail: website_detail,
        }
      )
    );
  } catch (err) {
    const errResponse = err.response;
    yield put(actions.checkFailed(errResponse));
    yield put(
      toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Could not fetch data. Refresh the page and try again.',
      })
    );
  }
}

function* submitWebsiteStatusWorker(action) {
  try {
    const websiteUUID = yield select(selectors.getWebsiteUUID);
    const { data } = yield call([axios, 'put'], API_ENDPOINTS.ONBOARDING.UPDATE_DOMAIN_STATUS(AuthManager.getMerchantUUID(), websiteUUID), {
      domain_status_by_merchant: action.payload,
    });
    yield put(
      merchantActions.updateMerchantDetails({
        website_detail: data.website_detail,
      })
    );
    const { payload: websiteStatus } = action;
    if (websiteStatus === 'active') {
      yield put(dialogActions.open('website-status-active'));
    } else {
      yield put(dialogActions.open('website-status-inactive'));
    }
  } catch (err) {
    const errResponse = err.response;
    yield put(
      toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Could not fetch data. Refresh the page and try again.',
      })
    );
  }
}

function* updateMerchantIntegrationTypeWorker(action) {
  try {
    let response = yield call([axios, "put"], API_ENDPOINTS.SUBMIT_ONBOARDING_DETAILS(AuthManager.getMerchantUUID()),
      { merchant: { integration_type: action.payload } },
    ) || {};
    if (response && response.data) yield put(uiActions.loadUI(response.data));
  }
  catch (err) {
    yield put(
      toastActions.show({
        variant: 'error',
        title: 'Error',
        content: 'Could not submit data. Refresh the page and try again.',
      })
    );
    yield put(uiActions.loadUI(yield select(merchantSelectors.getMerchantDetails), true));
  }
}

export function* saga() {
  yield takeLatest(actionTypes.sendOtp, otpWorker);
  yield takeLatest(actionTypes.checkWebsiteLiveliness, checkLivelinessWorker);
  yield takeLatest(actionTypes.submitWebsiteStatus, submitWebsiteStatusWorker);
  yield takeLatest(actionTypes.fetchCurrentWebsiteDetails, fetchCurrentWebsiteDetailsWorker);
  yield takeLatest(actionTypes.updateMerchantIntegrationType, updateMerchantIntegrationTypeWorker);
}
