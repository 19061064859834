import React from 'react';
import PropTypes from 'prop-types';
import { ActionCable } from 'react-actioncable-provider';

/**
 * actionCable consumer/subscriber component
 * Pass uniq identifier and channel name for subscribing
 * it takes a method in props to perform action
 * after receiving data from server.
 */
export default function ActionCableConsumer(props) {
  ActionCableConsumer.propTypes = {
    action: PropTypes.func.isRequired,
    channelName: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired
  };
  const { channelName, identifier } = props,
  onDataRecieved = (data) => props.action(data);
  return (
    <ActionCable
      channel={
        { channel: channelName,
          identifier }}
      onReceived={onDataRecieved}
    />
  );
}
