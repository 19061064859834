import { takeEvery, takeLatest, call, put, select } from "redux-saga/effects";

import API_ENDPOINTS from "@services/apiConfig";
import AuthManager from "@services/AuthManager";
import axios from 'axios';
import { uiActions, toastActions, merchantActions, merchantSelectors } from '.';
import { stepCompletionGTMEvent } from "@services/gtm";

export const actionTypes = {
  fetch: "businessDetails/INITIATE_FETCH",
  fetchFailed: "businessDetails/FETCH_FAILED",
  fetchFulfilled: "businessDetails/FETCH_FULFILLED",
  submitBusinessDetails: "businessDetails/SUBMIT_BUSINESS_DETAILS",
  submitFulfilled: "businessDetails/SUBMIT_FULFILLED",
  submitFailed: "businessDetails/SUBMIT_FAILED",
  fetchGstDetails: "gstDetails/INITIATE",
  fetchGstDetailsSuccess: "gstDetails/SUCCESS",
  fetchShopDetails: 'shopDetails/INITIATE',
  fetchShopDetailsSuccess: 'shopDetails/SUCCESS',
  fetchPanLinkedGST: 'fetchPanLinkedGST/INITIATE',
  savePanLinkedGST: 'savePanLinkedGST/SAVE_IN_STORE',
};

export const actions = {
  fetch: () => ({ type: actionTypes.fetch }),
  fetchFulfilled: (response) => ({
    type: actionTypes.fetchFulfilled,
    payload: response,
  }),
  fetchFailed: (errResponse) => ({
    type: actionTypes.fetchFailed,
    payload: errResponse,
  }),
  submitBusinessDetails: (businessDetails) => ({
    type: actionTypes.submitBusinessDetails,
    payload: businessDetails,
  }),
  submitFulfilled: () => ({
    type: actionTypes.submitFulfilled,
  }),
  submitFailed: (errResponse) => ({
    type: actionTypes.submitFailed,
    payload: errResponse,
  }),
  fetchGstDetails: (gstIn) => ({
    type: actionTypes.fetchGstDetails,
    payload: gstIn,
  }),
  fetchShopDetails: (reg_no, area_code) => ({
    type: actionTypes.fetchShopDetails,
    payload: { reg_no, area_code },
  }),
  fetchPanLinkedGST: () => ({ type: actionTypes.fetchPanLinkedGST }),
};

const initialState = {
  fetching: false,
  fetched: false,
  response: [],
  errResponse: null,
  submitFulfilled: false,
  submitFailed: false,
  tradeName: {
    gstIn: {},
    shop: {},
  },
  gstOptions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.fetch: {
      return {
        ...state,
        fetching: true,
      };
    }
    case actionTypes.fetchFulfilled: {
      return {
        ...state,
        fetched: true,
        response: action.payload,
      };
    }
    case actionTypes.fetchFailed: {
      return {
        ...state,
        fetched: false,
        errResponse: action.payload,
      };
    }
    case actionTypes.submitFulfilled: {
      return {
        ...state,
        submitFulfilled: true,
      };
    }
    case actionTypes.submitFailed: {
      return {
        ...state,
        submitFailed: true,
      };
    }
    case actionTypes.fetchGstDetailsSuccess:
      return {
        ...state, tradeName: {
          ...state.tradeName,
          gstIn: { ...state.tradeName.gstIn, ...action.payload },
        }
      };
    case actionTypes.fetchShopDetailsSuccess:
      return {
        ...state, tradeName: {
          ...state.tradeName,
          shop: { ...state.tradeName.shop, ...action.payload },
        }
      };
    case actionTypes.savePanLinkedGST:
      return { ...state, gstOptions: action.payload };
    default:
      return state;
  }
};

export const selectors = {
  getTradeOptions: state => state.onboardingApp.businessDetails.tradeName,
  getGSTINOptions: state => state.onboardingApp.businessDetails?.gstOptions,
};

function* fetchWorker() {
  try {
    const { data } = yield call(
      [axios, "get"],
      API_ENDPOINTS.ONBOARDING.BUSINESS_CATEGORY
    ) || { business_categories: [] };
    yield put(actions.fetchFulfilled(data.business_categories));
  } catch (err) {
    const errResponse = err.response;
    yield put(actions.fetchFailed(errResponse));
  }
}

const createReqBody = ({
  businessCategory,
  businessSubCategory,
  expectedSales,
  gstIn,
  tradeName,
  shopCode,
  areaCode,
}) => {
  let extraParams = {};
  if (tradeName) extraParams.business_name = tradeName.value;
  if (gstIn) extraParams.gst_number = gstIn.value;
  return {
    business_category_id: businessCategory.id,
    business_sub_category_id: businessSubCategory.id,
    monthly_expected_volume: expectedSales,
    ...extraParams,
    area_code: areaCode ? areaCode.value : '',
    shop_number: shopCode
  };
};

function* sendBusinessDetailsWorker(action) {
  const reqBody = createReqBody(action.payload);
  const pancard_name = yield select(merchantSelectors.getNameOnPanCard);
  if (!reqBody.business_name && pancard_name) reqBody.business_name = pancard_name;
  try {
    const { data } = yield call(
      [axios, "put"],
      API_ENDPOINTS.SUBMIT_ONBOARDING_DETAILS(AuthManager.getMerchantUUID()),
      { merchant: reqBody }
    );
    yield put(actions.submitFulfilled());
    yield put(uiActions.loadUI({ ...data.merchant, updatedBusinessDetails: true }));
    stepCompletionGTMEvent({ stepNo: '2', stepName: 'business_details_submitted'});
  } catch (err) {
    const errResponse = yield err.response;
    yield put(toastActions.show({
      variant: 'error',
      title: 'Error',
      content: errResponse?.data?.errors?.gst_number?.[0] || 'Something went wrong please try after some time'
    }));
    yield put(actions.submitFailed(errResponse));
  }
}

function* fetchGstDetailsWorker(action) {
  try {
    const { gstIn } = yield select(selectors.getTradeOptions);
    if (!gstIn[action.payload]) {
      const response = yield call(
        [axios, 'post'],
        API_ENDPOINTS.GST_DETAILS(AuthManager.getMerchantUUID()),
        { gstin: action.payload }
      ) || {};
      console.log(response.data);
      const { gstin_details } = response.data || {};
      yield put({
        type: actionTypes.fetchGstDetailsSuccess,
        payload: { [action.payload]: gstin_details && gstin_details.tradename }
      })
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchShopDetailsWorker(action) {
  const { reg_no, area_code } = action.payload;
  const { shop } = yield select(selectors.getTradeOptions);
  try {
    if (!shop[reg_no + area_code]) {
      const response = yield call(
        [axios, 'post'],
        API_ENDPOINTS.SHOP_DETAILS(AuthManager.getMerchantUUID()),
        { reg_no, area_code }
      ) || {};
      const { shop_details } = (response && response.data) || {};
      yield put({
        type: actionTypes.fetchShopDetailsSuccess,
        payload: { [reg_no + area_code]: shop_details ? shop_details.entity_name : null },
      })
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchPanLinkedGSTWorker() {
  const pan_number = yield select(merchantSelectors.getMerchantPancardNumber)
  try {
    const { data } = yield call(
      [axios, 'post'],
      API_ENDPOINTS.PAN_GSTIN(AuthManager.getMerchantUUID()),
      { pan_number },
    ) || {};
    if (data?.gst_details) {
      yield put({ type: actionTypes.savePanLinkedGST, payload: data?.gst_details })
    }
  } catch (err) {
    console.log(err);
  }
}

export function* saga() {
  yield takeEvery(actionTypes.fetch, fetchWorker);
  yield takeEvery(actionTypes.submitBusinessDetails, sendBusinessDetailsWorker);
  yield takeEvery(actionTypes.fetchGstDetails, fetchGstDetailsWorker);
  yield takeEvery(actionTypes.fetchShopDetails, fetchShopDetailsWorker);
  yield takeLatest(actionTypes.fetchPanLinkedGST, fetchPanLinkedGSTWorker);
}
